import React, { useEffect } from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, TrainingInfo } from "..";

export const DayTraining = () => {
  const checkServices = useStore((state) => state.checkServices);
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const trainingDetails = useStore((state) => state.trainingDetails);
  const dayTrainingPrograms = useStore((state) => state.dayTrainingPrograms);

  useEffect(() => {
    checkServices(trainingDetails);
  }, []);
  const overlay = {
    where: "dayTraining",
    title: "Day Training",
    caption:
      "Downtown Noblesville is a fantastic place to work on calm dog reactions during a day training session.",
  };
  const info = {
    routes: {
      Home: "/",
      Training: getLink(pathTitles.TRAINING),
      "Day Training": getLink(pathTitles.DAYTRAINING),
    },
    body: trainingDetails.DAYTRAINING.body,
    programs: dayTrainingPrograms,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <TrainingInfo info={info} />
        </>
      )}
    </>
  );
};
