import React from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, Team } from "..";

export const TeamSeeley = () => {
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const aboutDetails = useStore((state) => state.aboutDetails);

  const overlay = {
    where: "aboutSeeley",
    title: "Meet Seeley",
    caption: "Seeley, the Siamese cat",
  };
  const info = {
    routes: {
      Home: "/",
      "Todo's Team": getLink(pathTitles.MEETTHETEAM),
      "Meet Seeley": getLink(pathTitles.MEETSEELEY),
    },
    body: aboutDetails.SEELEY.body,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <Team info={info} />
        </>
      )}
    </>
  );
};
