import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

import groupDown2 from "../../Assets/StockPhotos/group_down_2-removebg-preview.png";
import training from "../../Assets/StockPhotos/training.jpeg";
// Store
import { pathTitles, useStore } from "../../Store/Store";

export const HomeAbout = () => {
  const getLink = useStore((state) => state.getLink);
  return (
    <>
      <Container className="home_about_container small_container clear">
        <Card className="about_container_inner">
          <Card.Body>
            <Card.Title className="about_pre_title">Welcome to</Card.Title>
            <Card.Title className="about_main_title">
              Todo&apos;s Translator
            </Card.Title>
            <Card.Body className="about_body">
              <h3>
                Both you and your dog will love finding <i>solutions </i>
                together through our individualized training methods.
              </h3>
            </Card.Body>
            <Card.Body className="about_body about_questions">
              <h4>
                Does your dog drive you a little crazy with its bad habits?
              </h4>
              <h4>
                Are you sick of adjusting your life due to your dogs behavior?
              </h4>
              <h4>Would you like a calm, peaceful life with your dog?</h4>
              <h3>
                You&apos;re in the right place! Todo&apos;s Translator is here
                to help!
              </h3>
              Servicing the Greater Indianapolis Area including Carmel,
              Noblesville, Zionsville and Greenwood.
            </Card.Body>

            <Card.Body className="about_body">
              We own dogs hoping to have a happy and fulfilling relationship
              together. Unfortunately, that isn&apos;t always the case right off
              the bat. Let Justine Ramey, trainer and owner of Todo&apos;s
              Translator, help you learn to have the dog you dreamed of.
            </Card.Body>
            <Card.Body className="about_body">
              Todo&apos;s Translator, Indy&apos;s In Home Dog Trainer, comes to
              you and faces your training goals head on using &quot;positive
              first&quot; training methods. This means we will focus on
              rewarding good choices and will manage bad, or unwanted, behaviors
              through controlling the learning environment and using appropriate
              consequences for your dog, the situation, and the end goal. No
              matter the service you choose, everything taught to your dog will
              also be taught to you.
            </Card.Body>
            <Button
              as={Link}
              className="home_about_button"
              to={getLink(pathTitles.TRAINING)}
            >
              Learn More
            </Button>
          </Card.Body>
        </Card>
        <Row className="about_row">
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 4, offset: 1 }}
            className="about_col"
          >
            <Card.Img className="about_image_rounded" src={training} />
            <figcaption className="figure-caption">
              When learning place remember the importance of clear hand signals.
            </figcaption>
          </Col>
          <Col
            md={{ span: 4, offset: 2 }}
            className="d-none d-sm-block about_col"
          >
            <Card.Img
              className="about_image_rounded group_down"
              src={groupDown2}
            />
            <figcaption className="figure-caption">
              Everyone pictured is 6 months old or younger, and learning
              patience.
            </figcaption>
          </Col>
        </Row>
      </Container>
      <Dropdown.Divider />
    </>
  );
};
