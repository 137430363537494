import React, { useEffect } from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, TrainingInfo } from "..";

export const BoardAndTrain = () => {
  const checkServices = useStore((state) => state.checkServices);
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const trainingDetails = useStore((state) => state.trainingDetails);

  useEffect(() => {
    checkServices(trainingDetails);
  }, []);
  const overlay = {
    where: "boardAndTrain",
    title: "Board and Train",
    caption:
      "Justine walking Pez, Chico, Charlie, and Bear - all of whom came to her unable to walk on a leash.",
  };
  const info = {
    routes: {
      Home: "/",
      Training: getLink(pathTitles.TRAINING),
      "Board And Train": getLink(pathTitles.BOARDANDTRAIN),
    },
    body: trainingDetails.BOARDANDTRAIN.body,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <TrainingInfo info={info} />
        </>
      )}
    </>
  );
};
