import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { actions, useStore } from "../../Store/Store";

export const ContactUsForm = () => {
  const dispatch = useStore((state) => state.dispatch);
  const user = useStore((state) => state.user);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    fetch("https://msr-todos-web-api.herokuapp.com/auth/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: user.username,
        password: user.password,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.token) {
          dispatch({ type: actions.LOGIN, payload: response.token });
        }
      });
  }, []);

  const handleFormChange = (e) => {
    let inputName = e.target.name;
    let inputValue = e.target.value;
    setFormData((state) => ({ ...state, [inputName]: inputValue }));
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      fetch("https://msr-todos-web-api.herokuapp.com/contact/send_email/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + user.token,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status_code === 200) {
            dispatch({
              type: actions.TOAST,
              payload: {
                message:
                  "Successfully emailed Justine! She'll reach out to you soon.",
                statusCode: 200,
              },
            });
            resetForm();
          } else {
            dispatch({
              type: actions.TOAST,
              payload: {
                message: "An error occurred. Please try again",
                statusCode: 400,
              },
            });
          }
        });
    }
    setValidated(true);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      message: "",
    });
    setValidated(false);
  };
  return (
    <>
      <Container className="contact_section small_container">
        <Row>
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 5, offset: 0 }}>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e)}
              className="contact_us_form"
            >
              <p className="h2 text-center m-4">Contact Us</p>
              <p className="h5 text-center mb-2">
                Our professional dog training services are conducted by
                full-time, professional trainers who truly care about your dog
                and want the very best for them and for you.
              </p>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide your name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email address to reach you at.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Phone"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a phone number Justine can reach you at.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  required
                  type="message"
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please let us know how we can help.
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="contact_section_button" type="submit">
                Submit&nbsp;&nbsp;&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                </svg>
              </Button>

              <figcaption className="figure-caption">
                Penny, a 3 mo. Labradoodle, practicing impulse control and focus
                in a busy pet store.
              </figcaption>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
