import React, { useEffect } from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, TrainingInfo } from "..";

export const TrainingAll = () => {
  const checkServices = useStore((state) => state.checkServices);
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const trainingDetails = useStore((state) => state.trainingDetails);

  useEffect(() => {
    checkServices(trainingDetails);
  }, []);
  const overlay = {
    where: "trainingAll",
    title: "Training",
    caption:
      "Justine helped me learn how to ride in the buggy behind my mom's bike.",
  };
  const info = {
    routes: {
      Home: "/",
      Training: getLink(pathTitles.TRAINING),
    },
    body: trainingDetails.ALLTRAININGPROGRAMS.body,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <TrainingInfo info={info} />
        </>
      )}
    </>
  );
};
