import React from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { PageOverLay, Team } from "..";

export const TeamAll = () => {
  const overlay = {
    where: "teamAll",
    title: "Meet the Team",
    caption:
      "Aurora (left), 2 year old Cane Corso, and Zeta (right), 6 year old Pit Bull Mix, with Justine.",
  };

  const getLink = useStore((state) => state.getLink);
  const aboutDetails = useStore((state) => state.aboutDetails);

  const info = {
    routes: {
      Home: "/",
      "Todo's Team": getLink(pathTitles.MEETTHETEAM),
    },
    body: aboutDetails.THETEAM.body,
  };
  return (
    <>
      <PageOverLay overlay={overlay} />
      <Team info={info} />
    </>
  );
};
