import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

// Components
import { PageOverLay } from "../Components";
// Store
import { pathTitles, useStore } from "../Store/Store";

export const Testimonials = () => {
  const getLink = useStore((state) => state.getLink);
  const testimonials = useStore((state) => state.testimonials);
  const w = useStore((state) => state.width);
  const overlay = {
    where: "aboutZeta",
    title: "Testimonials",
    caption: "Justine and Zeta practice 'Watch Me'.",
  };
  const info = {
    routes: {
      Home: "/",
      Testimonials: getLink(pathTitles.TESTIMONIALS),
    },
  };
  let width = "100%";
  if (w > 600) {
    width = "640px";
  }
  return (
    <>
      <PageOverLay overlay={overlay} />
      <Container className="small_container common_container">
        <Row>
          <Card.Header className="common_header">
            {Object.keys(info.routes).map((routeName, i) => {
              return (
                <>
                  <Link
                    key={`${info.routes[routeName]}_${i}`}
                    to={info.routes[routeName]}
                  >
                    {routeName}
                  </Link>
                  {i < Object.keys(info.routes).length - 1 && (
                    <>&nbsp;/&nbsp; </>
                  )}
                </>
              );
            })}
          </Card.Header>
        </Row>
        <Row className="testimonial_row">
          <Col>
            <Card className="common_card">
              <Card.Title>Testimonials</Card.Title>
              <div
                className="player-wrapper"
                style={{ margin: "0 auto 10rem auto" }}
              >
                <ReactPlayer
                  className="react-player"
                  url="https://youtu.be/s-LBYppQOao"
                  width={width}
                />
              </div>
            </Card>
          </Col>
          <Col>
            {testimonials.map((t, i) => (
              <Card
                key={`${t.clientName}_${i}`}
                className={`testimonial_card ${i % 2 ? "right" : "left"}`}
              >
                <Card.Body>{t.comment}</Card.Body>
                <Card.Header>
                  {t.clientName},&nbsp;&nbsp;{t.location}
                </Card.Header>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};
