// Stylesheets
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Css/App.css";
import "./Assets/Css/IconHeader.css";
import "./Assets/Css/Logo.css";
import "./Assets/Css/Navigation.css";
import "./Assets/Css/HomeOverLay.css";
import "./Assets/Css/HomeAbout.css";
import "./Assets/Css/Footer.css";
import "./Assets/Css/HomeServicesOffered.css";
import "./Assets/Css/GoogleReviews.css";
import "./Assets/Css/Toast.css";
import "./Assets/Css/ContactUsForm.css";
import "./Assets/Css/PageOverLay.css";
import "./Assets/Css/TrainingInfo.css";
import "./Assets/Css/AboutInfo.css";
import "./Assets/Css/ContactUs.css";
import "./Assets/Css/Testimonials.css";
import "./Assets/Css/Common.css";
import "./Assets/Css/NotFound.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Components
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./ScrollToTop";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
