import PropTypes from "prop-types";
import React from "react";
import { Accordion, Button, Nav } from "react-bootstrap";

// Store
import { pathTitles, useStore } from "../../Store/Store";

export const Programs = (props) => {
  const program = props.program;
  const getLink = useStore((state) => state.getLink);
  // web browser
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let mobile = false;
  let consultPath = getLink(pathTitles.CONTACTUS);
  if (/android/i.test(userAgent) || /iPad|iPhone|iPod/i.test(userAgent)) {
    // mobile
    consultPath = "sms://+13179546952";
    mobile = true;
  }
  return (
    <>
      <Accordion className="training_accordion" key={program.programName}>
        <Accordion.Item eventKey={program.programName}>
          <Accordion.Header>{program.programName}</Accordion.Header>
          <Accordion.Body>{program.definition}</Accordion.Body>
          {program.image && (
            <div className={`${program.image} common_accordion_image`}></div>
          )}
          <Accordion.Body className="training_accordion_body">
            <h5>Included&nbsp;:&nbsp;</h5>
            {program.included.map((p, i) => (
              <li key={`${p[1]}_${i}`}>{p}</li>
            ))}
            <h5>Cost&nbsp;:&nbsp;&nbsp;{program.cost}</h5>
            <h5>What you can expect&nbsp;:&nbsp;</h5>
            {program.expectations.map((e, i) => (
              <li key={`${e[1]}_${i}`}>{e}</li>
            ))}
            <Button
              className="training_accordion_button"
              as={Nav.Link}
              href={consultPath}
            >
              {mobile ? "Reach out Now" : "Book Now"}
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

Programs.propTypes = {
  program: PropTypes.object,
};
