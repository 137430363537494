import React from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, Team } from "..";

export const TeamMunch = () => {
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const aboutDetails = useStore((state) => state.aboutDetails);

  const overlay = {
    where: "aboutMunch",
    title: "Meet Munch",
    caption: "Munch is ready to obey.. provided you have treats!",
  };
  const info = {
    routes: {
      Home: "/",
      "Todo's Team": getLink(pathTitles.MEETTHETEAM),
      "Meet Munch": getLink(pathTitles.MEETMUNCH),
    },
    body: aboutDetails.MUNCH.body,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <Team info={info} />
        </>
      )}
    </>
  );
};
