import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

// Store
import { useStore } from "../../Store/Store";
// Components
import { IconHeader } from "..";

export const Footer = () => {
  const paths = useStore((state) => state.paths);

  return (
    <>
      <IconHeader top_or_bottom="bottom" />
      <Navbar expand="lg" className="navbar_footer">
        {paths.map((path, i) => {
          if (path.footer) {
            return (
              <Nav.Link key={i} as={Link} to={path.path} className="text-white">
                {path.displayName}
              </Nav.Link>
            );
          }
        })}
      </Navbar>
    </>
  );
};
