import React from "react";

// Components
import {
  ContactUsForm,
  GoogleReviews,
  HomeAbout,
  HomeOverLay,
  HomeServicesOffered,
} from "../Components";

export const Home = () => {
  return (
    <>
      <HomeOverLay />
      <HomeAbout />
      <HomeServicesOffered />
      <GoogleReviews />
      <ContactUsForm />
    </>
  );
};
