import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// Store
import { pathTitles, useStore } from "../../Store/Store";

export const HomeOverLay = () => {
  const getLink = useStore((state) => state.getLink);
  return (
    <>
      <div className="home_overlay">
        <div className="home_overlay_slides">
          <div className="overlay_slide">
            <span className="overlay_slide_bg_wrap">
              <span className="overlay_slide_bg"></span>
            </span>
            <Container className="home_overlay_container clear">
              <Card className="overlay_container_inner">
                <Card.Body>
                  <Card.Body>
                    <h2>You Deserve</h2>
                    <h2>Stress-Free</h2>
                    <h2>Adventures </h2>
                    <h2>With Your Dog.</h2>
                  </Card.Body>
                  <Button
                    className="home_overlay_button"
                    as={Link}
                    to={getLink(pathTitles.CONTACTUS)}
                  >
                    Book now
                  </Button>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
