import React from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, Team } from "..";

export const TeamJustine = () => {
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const aboutDetails = useStore((state) => state.aboutDetails);

  const overlay = {
    where: "aboutJustine",
    title: "Meet The Trainer",
    caption:
      "Justine taught four dogs from two different households to loose leash walk in under a month.",
  };
  const info = {
    routes: {
      Home: "/",
      "Todo's Team": getLink(pathTitles.MEETTHETEAM),
      "Meet The Trainer": getLink(pathTitles.MEETTHETRAINER),
    },
    body: aboutDetails.JUSTINE.body,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <Team info={info} />
        </>
      )}
    </>
  );
};
