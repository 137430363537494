import PropTypes from "prop-types";
import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Store
import { pathTitles, useStore } from "../../Store/Store";

export const Team = (props) => {
  const info = props.info;
  const getLink = useStore((state) => state.getLink);

  return (
    <>
      <Container className="small_container common_container">
        <Row>
          <Card.Header className="common_header">
            {Object.keys(info.routes).map((routeName, i) => {
              return (
                <>
                  <Link
                    key={`${info.routes[routeName]}_${i}`}
                    to={info.routes[routeName]}
                  >
                    {routeName}
                  </Link>
                  {i < Object.keys(info.routes).length - 1 && (
                    <>&nbsp;/&nbsp; </>
                  )}
                </>
              );
            })}
          </Card.Header>
        </Row>
        <Row>
          <Col>
            <Card className="common_card">
              <Card.Title>{info.body.title}</Card.Title>
              <div className={`${info.body.image} common_image`}></div>
              <figcaption className="figure-caption">
                {info.body.imageCaption}
              </figcaption>
              <Card.Body>{info.body.info}</Card.Body>
              {info.body.moreInfo?.length > 0 &&
                info.body.moreInfo.map((p, i) => {
                  return <Card.Body key={i}>{p}</Card.Body>;
                })}
              {info.body.otherMembers && (
                <>
                  <Button
                    className="about_otherMembers_button"
                    as={Link}
                    to={getLink(pathTitles.MEETTHETRAINER)}
                    variant="warning"
                  >
                    Read More
                  </Button>
                </>
              )}
              {info.body.otherMembers &&
                Object.keys(info.body.otherMembers).map((serviceName, i) => {
                  let left = "floatRight";
                  if (i % 2 == 0) left = "floatLeft";
                  return (
                    <div
                      className={left}
                      key={`${info.body.otherMembers[serviceName]}_${i}`}
                    >
                      <div
                        className={`${info.body.otherMembers[serviceName].image} about_otherMembers_image`}
                      >
                        <figcaption className="figure-caption">
                          {info.body.otherMembers[serviceName].imageCaption}
                        </figcaption>
                      </div>
                      <Card className="about_otherMembers_card">
                        <Card.Title>
                          {info.body.otherMembers[serviceName].title}
                        </Card.Title>
                        <Card.Body>
                          {info.body.otherMembers[serviceName].info}
                        </Card.Body>
                        {info.body.otherMembers[serviceName].link.length >
                          0 && (
                          <Button
                            className="about_otherMembers_button"
                            as={Link}
                            to={info.body.otherMembers[serviceName].link}
                            variant="warning"
                          >
                            Read More
                          </Button>
                        )}
                      </Card>
                    </div>
                  );
                })}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Team.propTypes = {
  info: PropTypes.object,
};
