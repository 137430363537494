import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Route, Switch, useLocation } from "react-router-dom";

// Components
import {
  Footer,
  IconHeader,
  Logo,
  // Navigation,
  ToastComponent,
} from "./Components";
// Store
import { pathTitles, useStore } from "./Store/Store";
import {
  About,
  ContactUs,
  Home,
  NotFound,
  Testimonials,
  Training,
} from "./Views";

function App() {
  const getLink = useStore((state) => state.getLink);
  const checkServices = useStore((state) => state.checkServices);
  const trainingDetails = useStore((state) => state.trainingDetails);
  const getWindow = useStore((state) => state.getWindow);
  useEffect(() => {
    checkServices(trainingDetails);
    getWindow();
  }, []);

  let location = useLocation();
  let show = true;
  if (location.pathname != "/") show = false;
  return (
    <>
      <div id="blue_ink" className="wrapper bg-image">
        <ToastComponent />
        <IconHeader top_or_bottom="top" showLogo={!show} />
        {show && <Logo />}
        <div className="content-page">
          <Container fluid className="no-gutters main_container">
            {/* <span className="no_show_elm navigation_component">
              <Navigation mobile={false} expand={true} />
            </span> */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path={getLink(pathTitles.TRAINING)} component={Training} />
              <Route path={getLink(pathTitles.MEETTHETEAM)} component={About} />
              <Route
                path={getLink(pathTitles.CONTACTUS)}
                component={ContactUs}
              />
              <Route
                path={getLink(pathTitles.TESTIMONIALS)}
                component={Testimonials}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
