import React from "react";
import { Route, Switch } from "react-router-dom";

// Components
import {
  TeamAll,
  TeamAurora,
  TeamJustine,
  TeamKyra,
  TeamMunch,
  TeamSeeley,
  TeamZeta,
} from "../Components";
import { pathTitles, useStore } from "../Store/Store";

export const About = () => {
  const getLink = useStore((state) => state.getLink);
  return (
    <>
      <Switch>
        <Route path={getLink(pathTitles.MEETAURORA)} component={TeamAurora} />
        <Route
          path={getLink(pathTitles.MEETTHETRAINER)}
          component={TeamJustine}
        />
        <Route path={getLink(pathTitles.MEETKYRA)} component={TeamKyra} />
        <Route path={getLink(pathTitles.MEETMUNCH)} component={TeamMunch} />
        <Route path={getLink(pathTitles.MEETSEELEY)} component={TeamSeeley} />
        <Route path={getLink(pathTitles.MEETZETA)} component={TeamZeta} />
        <Route path="/" component={TeamAll} />
      </Switch>
    </>
  );
};
