import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

// Store
import { useStore } from "../../Store/Store";

export const Navigation = (props) => {
  const { mobile, expand } = props;
  const paths = useStore((state) => state.paths);
  const [expanded, setExpanded] = useState(false);

  let added = [];
  let element;

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [expanded]);
  const handleClick = (e) => {
    const container = element;
    if (container && !container.contains(e.target)) {
      setExpanded(false);
    }
  };
  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        expand={expand ? "lg" : false}
        expanded={expanded}
        className="navigation_navbar"
        ref={(c) => (element = c)}
      >
        {expand && (
          <Navbar.Brand as={Link} to="/" className="navigation_brand">
            Todo&apos;s Translator
          </Navbar.Brand>
        )}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
          className="navigation_toggle"
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={`navbar_collapse ${mobile ? "mobile" : ""}`}
        >
          <Nav className="mr-auto">
            {paths.map((path, i) => {
              if (path.header) {
                let alreadyAdded = added.some(
                  (el) => el.displayName === path.displayName
                );
                if (path.dropDown && !alreadyAdded) {
                  let parent = paths.find(
                    (p) => p.parentDrop === path.dropDown
                  );
                  let dropDown = paths.filter((p) => {
                    if (p.dropDown) {
                      return p.dropDown.includes(parent.parentDrop);
                    }
                  });
                  added.push(...dropDown);
                  return (
                    <NavDropdown
                      key={i}
                      menuVariant="dark"
                      title={parent.displayName}
                      id="basic-nav-dropdown"
                    >
                      {dropDown.map((list, l) => (
                        <NavDropdown.Item
                          key={l}
                          as={Link}
                          to={list.path}
                          onClick={() =>
                            setTimeout(() => {
                              setExpanded(false);
                            }, 150)
                          }
                        >
                          {list.displayName}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  );
                }
                if (!alreadyAdded) {
                  added.push(path.path);
                  return (
                    <Nav.Link
                      key={i}
                      as={Link}
                      to={path.path}
                      onClick={() => {
                        setTimeout(() => {
                          setExpanded(false);
                        }, 150);
                      }}
                    >
                      {path.displayName}
                    </Nav.Link>
                  );
                }
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

Navigation.propTypes = {
  mobile: PropTypes.bool,
  expand: PropTypes.bool,
};
