import React, { useEffect } from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, TrainingInfo } from "..";

export const Boarding = () => {
  const checkServices = useStore((state) => state.checkServices);
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const trainingDetails = useStore((state) => state.trainingDetails);

  useEffect(() => {
    checkServices(trainingDetails);
  }, []);
  const overlay = {
    where: "boarding",
    title: "Boarding",
    caption: "Rosie and Coco snore loudly after a fun day of play.",
  };
  const info = {
    routes: {
      Home: "/",
      Training: getLink(pathTitles.TRAINING),
      Boarding: getLink(pathTitles.BOARDING),
    },
    body: trainingDetails.BOARDING.body,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <TrainingInfo info={info} />
        </>
      )}
    </>
  );
};
