import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <>
      <Container className="small_container not_found common_container">
        <Row>
          <Col>
            <Card className="not_found common_card">
              <div className="not_found common_image"></div>
              <Card.Title>404: Page Not Found</Card.Title>
              <Card.Body>
                Sorry, we can&apos;t find that page. Somebody may have chewed up
                the power cord. Go back to our main page to continue your visit.
              </Card.Body>
              <Button className="not_found_button" as={Link} to="/">
                Back to main page
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
