import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";

// Store
import { useStore } from "../../Store/Store";

export const GoogleReviews = () => {
  //   useEffect(() => {
  //     fetch(
  //       "https://mybusiness.googleapis.com/v4/accounts/{accountId}/locations/{locationId}/reviews"
  //     )
  //       .then((responseObject) => responseObject.json())
  //       .then((hydratedBody) => console.log(hydratedBody));
  //   }, []);

  const googleReviews = useStore((state) => state.googleReviews);
  let [googleReviewsStart, setGoogleReviewsStart] = useState(0);
  let [currentReview, setCurrentReview] = useState(googleReviews[0]);

  useEffect(() => {
    let rotate = setInterval(() => {
      let currentIndex = googleReviewsStart++;
      if (googleReviewsStart < 7) currentIndex++;
      else currentIndex = 0;
      setCurrentReview(googleReviews[currentIndex]);
      setGoogleReviewsStart(currentIndex);
      clearInterval(rotate);
    }, 15000);
    // Cleanup the setInterval
    return () => {
      clearInterval(rotate);
    };
  }, [googleReviewsStart]);

  const createImageFromInitials = (size, name) => {
    if (name == null) return;
    name = getInitials(name);
    let color = getRandomColor();
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = canvas.height = size;

    context.fillStyle = "#ffffff";
    context.fillRect(0, 0, size, size);

    context.fillStyle = `${color}50`;
    context.fillRect(0, 0, size, size);

    context.fillStyle = color;
    context.textBaseline = "middle";
    context.textAlign = "center";
    context.font = `${size / 2}px Roboto`;
    context.fillText(name, size / 2, size / 2);

    return canvas.toDataURL();
  };
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
      initials =
        nameSplit[0].substring(0, 1) +
        nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
      initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
  };
  return (
    <>
      <Container className="google_reviews_container small_container">
        <Row>
          <div className="google_reviews bg_div"></div>
          <Col>
            <Card className="google_reviews card_inner">
              <Card.Header className="google_reviews card_header second">
                <Image
                  src={
                    currentReview.profileImage.length
                      ? currentReview.profileImage
                      : createImageFromInitials(50, currentReview.reviewerName)
                  }
                  roundedCircle
                />
                &nbsp;&nbsp; {currentReview.reviewerName}
              </Card.Header>
              <Card.Title className="google_reviews_title">
                &nbsp;&nbsp;
                {[...Array(currentReview.stars)].map(() => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-star-fill text-warning"
                    viewBox="0 0 16 16"
                    key={Math.random()}
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                ))}
              </Card.Title>
              {currentReview.keywords.length > 0 && (
                <Card.Body className="google_reviews card_body">
                  <strong>
                    {currentReview.positive ? "Positive " : "Negative "}:&nbsp;
                  </strong>
                  {currentReview.keywords}
                </Card.Body>
              )}
              <Card.Body className="google_reviews card_body">
                {currentReview.review}
              </Card.Body>

              {currentReview.images.length > 0 && (
                <Row>
                  {currentReview.images.map((image, i) => {
                    let max = currentReview.images.length;
                    let span = 12 / max;
                    return (
                      <Col
                        xs={{ span: span }}
                        key={`${currentReview.image}_${i}`}
                      >
                        <Image
                          className="google_reviews column_image"
                          src={image}
                        />
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
