import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Image,
  Nav,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import crate_training from "../../Assets/StockPhotos/crate_training.png";
import puppy_snout from "../../Assets/StockPhotos/puppy_snout.png";
import training from "../../Assets/StockPhotos/training_in_public.png";
// Store
import { pathTitles, useStore } from "../../Store/Store";

export const HomeServicesOffered = () => {
  const getLink = useStore((state) => state.getLink);
  // web browser
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let mobile = false;
  let consultPath = getLink(pathTitles.CONTACTUS);
  if (/android/i.test(userAgent) || /iPad|iPhone|iPod/i.test(userAgent)) {
    // mobile
    consultPath = "sms://+13179546952";
    mobile = true;
  }
  const services = [
    {
      cardHeaderTitle: "Consultation ($40)",
      cardButtonPath: consultPath,
      cardButtonMsg: mobile ? "Reach out Now" : "Learn More",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          The first step in the process is to have Justine Ramey, owner and
          trainer, come to your home to discuss in detail your dog’s current
          behavior, what you’ve tried and what your end goals are. The more
          behavior she can witness the better (videos help too!). From there she
          walks you through Todo’s Translators services and pricing to make an
          individualized training plan for your specific situation.
        </Card.Body>,
        <Card.Body key={Math.random()} className="services_offered card_body">
          The consultation is non-committal, will take an hour to an hour and a
          half and costs $40.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "Private In Home Lessons",
      cardButtonPath: getLink(pathTitles.PRIVATELESSONS),
      cardButtonMsg: "Learn More",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          Justine will come to your home or the location of your problem
          behaviors to work through them with you. During these lessons Justine
          teaches you how to train your dog. Lessons are to educate you; the
          real change in behavior happens outside of lessons when you institute
          what you have learned into your every day life.
        </Card.Body>,
        <Card.Body key={Math.random()} className="services_offered card_body">
          <i>
            All other programs include lessons to teach you how to work with
            your dog.
          </i>
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "Board and Train",
      cardButtonPath: getLink(pathTitles.BOARDANDTRAIN),
      cardButtonMsg: "Learn More",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          This is the most intensive and fastest way to see a change in your
          dog’s behavior. Your pup goes to live with Justine in her house along
          side her family to be completely immersed in training all day long.
          Every interaction with our dogs is an opportunity for the dogs to
          learn. Board and Train in Justine’s home is a unique way to hit the
          “reset button” on how your dog behaves. The dog will unlearn old
          habits and reform new ones. Dogs who Board and Train with Justine
          spend time socializing with other dogs, enjoying the half acre fenced
          in yard, practicing calm polite behavior in the kitchen and living
          room as well as how to behave in public. The longer the board and
          train the more ingrained your dog’s new habits will be.
        </Card.Body>,
        <Card.Body key={Math.random()} className="services_offered card_body">
          <i>
            Lessons for you during your dog’s board and train are a necessity to
            ensure you maintain what your dog has learned.
          </i>
        </Card.Body>,
      ],
    },
  ];
  const servicesSecondRow = [
    {
      colSizeSmall: {
        span: 3,
      },
      imageSource: puppy_snout,
      imageCaption:
        "Rowan, 5 mo. female golden doodle, taking a nap at the vet.",
      cardHeaderTitle: "Buy a Trained Puppy",
      cardBody: [
        `If you’ve ever wanted a puppy without having to deal with the potty accidents, crying in the crate, biting and chewing then you’re in luck!  Todo’s Translator will start puppies right from the breeder at 8 weeks to lay a fantastic foundation covering not only potty training, crate training, not biting and jumping but also “learned calmness” where the pup’s default is calm, polite behavior in public and at home.`,
        `Choose from one of our available puppies or meet with Justine to work together to find the perfect new family member for you and your lifestyle.`,
      ],
      cardButtonPath: getLink(pathTitles.BUYATRAINEDPUPPY),
    },
    {
      colSizeSmall: { span: 3, offset: 1 },
      imageSource: training,
      imageCaption: `Omega, a 7 mo Golden Retriever, waiting for pets and attention
      at a public market.`,
      cardHeaderTitle: "Day Training",
      cardBody: [
        `During Day Training sessions Justine will spend up to 3 hours at a time working with your dog one on one – you will not attend.  These sessions are great for extra repetition or to get new behaviors started.  The more hands on Justine can be with your dog the faster you will see a change in behavior.  These sesssions may be in your home or Justine may take your dog out into the world to practice.`,
        `Day Training will be alongside lessons for you.`,
      ],
      cardButtonPath: getLink(pathTitles.DAYTRAINING),
    },
    {
      colSizeSmall: { span: 3, offset: 1 },
      imageSource: crate_training,
      imageCaption:
        "Grace, a 6 mo. Black Lab, practicing crate training in her home.",
      cardHeaderTitle: "Boarding",
      cardBody: [
        `Boarding includes as much time out of the kennel as your dog
        needs, and if your dog is social, it includes plenty of
        supervised playtime with other dogs!`,
        `Boarding is only available to training clients.`,
      ],
      cardButtonPath: getLink(pathTitles.BOARDING),
    },
  ];
  const servicesThirdRow = [
    {
      cardHeaderTitle:
        "Many Individualized Training Plans to help you reach your specific goals.",
      cardBody: [
        `Your dog is unique and needs a tailored training approach, so
        we offer a variety of board and train options, as well as
        private and in-home lessons. We want to make sure our approach
        will lead to the best results for your situation.`,
      ],
    },
    {
      cardHeaderTitle: "Justine is Awesome",
      cardBody: [
        `Over the years since opening for business in 2015 Justine has helped
        numerous families to enjoy their dogs to the fullest. She is dedicated
         to her client's success and it shows.`,
      ],
    },
    {
      cardHeaderTitle: "No Force, Just Fun!",
      cardBody: [
        ` Justine uses non-forceful training methods; a combination of
        positive reinforcement, clicker training, and communicating
        with dogs by using and reading body language. The goal is to
        see a relationship based on mutual respect between you and your
        dog.`,
        `Justine also helps her clients to implement consequences that are
        appropriate for their dog and situation.`,
      ],
    },
  ];
  return (
    <>
      <Container className="home_services_offered_container small_container clear">
        <Row>
          <Col sm={{ span: 4, offset: 1 }} md={{ span: 6, offset: 3 }}>
            <Card.Title className="services_offered_title">Services</Card.Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {services.map((service, i) => (
            <Col
              key={i}
              sm={{ span: 4, offset: 1 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 4 }}
            >
              <Card className="services_offered card_inner">
                <Card.Body>
                  <Card.Header className={`services_offered card_header`}>
                    {service.cardHeaderTitle}
                  </Card.Header>
                  <Button
                    className="services_offered card_button"
                    as={Nav.Link}
                    href={service.cardButtonPath}
                  >
                    {service.cardButtonMsg}
                  </Button>
                  {service.cardBody.map((body) => body)}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="services_offered_row">
          <Col xs={{ offset: 1, span: 10 }} md={{ span: 5 }} lg={{ span: 4 }}>
            <Button
              className="services_offered programs_button"
              as={Link}
              to={getLink(pathTitles.TRAINING)}
            >
              Check out more of our programs
            </Button>
          </Col>
        </Row>
        <Row className="services_offered second_row justify-content-center">
          <div className="services_offered second_row_divider"></div>
          {servicesSecondRow.map((service, i) => (
            <Col
              key={i}
              sm={service.colSizeSmall}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 4 }}
            >
              <Card className="services_offered card_inner transparent">
                <Image roundedCircle src={service.imageSource} />
                <figcaption className="figure-caption">
                  {service.imageCaption}
                </figcaption>
                <Card.Body>
                  <Card.Header className="services_offered card_header first">
                    {service.cardHeaderTitle}
                  </Card.Header>
                  {service.cardBody.map((body, i) => (
                    <Card.Body key={i} className="services_offered card_body">
                      {body}
                    </Card.Body>
                  ))}
                  <Button
                    className="services_offered second_row_card_button"
                    as={Link}
                    to={service.cardButtonPath}
                  >
                    Learn More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="services_offered_third_row">
          <div className="third_row_divider"></div>
          <Dropdown.Divider />
          <Col
            sm={{ span: 4, offset: 1 }}
            md={{ span: 6, offset: 3 }}
            className="services_offered_title_col"
          >
            <Card.Title className="services_offered_title">
              Why Choose Us?
            </Card.Title>
          </Col>
          {servicesThirdRow.map((service, i) => (
            <Col
              key={i}
              sm={{ span: 4, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 4, offset: 0 }}
            >
              <Card className="services_offered card_inner why_us">
                <Card.Body>
                  <Card.Header className="services_offered card_header first">
                    {service.cardHeaderTitle}
                  </Card.Header>
                  {service.cardBody.map((body, i) => (
                    <Card.Body key={i} className="services_offered card_body">
                      {body}
                    </Card.Body>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};
