// import { useHistory } from 'react-router-dom';
// import { useEffect } from 'react';
import create from "zustand";
import { devtools } from "zustand/middleware";

export const actions = {
  /** Log in the provided user. Payload is the new user object. */
  LOGIN: "LOGIN",
  // /** Log out the current user. No payload. */
  // LOGOUT: 'LOGOUT',
  /** Display a toast. Payload { String message, Number statusCode } */
  TOAST: "TOAST",
  /** Clear the current toast. No payload. */
  UNTOAST: "UNTOAST",
  /** Set the message list. Payload is an array of messages. */
  UPDATE_MESSAGES: "UPDATE_MESSAGES",
  /** Reset the message lsit. No payload. */
  RESET_MESSAGES: "RESET_MESSAGES",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return {
        user: {
          ...state.user,
          token: action.payload,
        },
      };
    // case actions.LOGOUT:
    //   return {
    //     user: initialState.user,
    //     toast: toastFor(action, 'Log out successful', 200)
    //   };
    case actions.TOAST:
      return { toast: { ...state.toast, ...action.payload } };
    case actions.UNTOAST:
      return { toast: state.toast };
    case actions.UPDATE_MESSAGES:
      return { messages: action.payload };
    case actions.RESET_MESSAGES:
      return { messages: state.messages };
    default:
      return state;
  }
};

export const pathTitles = {
  HOME: "Home",
  TRAINING: "Training",
  ALLTRAININGPROGRAMS: "All Training Programs",
  PRIVATELESSONS: "Private Lessons",
  BOARDANDTRAIN: "Board And Train",
  BOARDANDTRAINADULT: "Adult Dog Board And Train",
  BOARDANDTRAINPUPPY: "Puppy Board And Train",
  BUYATRAINEDPUPPY: "Buy A Trained Puppy",
  DAYTRAINING: "Day Training",
  BOARDING: "Boarding",
  MEETTHETEAM: "Meet The Family",
  MEETAURORA: "Aurora",
  MEETTHETRAINER: "Meet The Trainer",
  MEETKYRA: "Kyra",
  MEETMUNCH: "Munch",
  MEETSEELEY: "Seeley",
  MEETZETA: "Zeta",
  TESTIMONIALS: "Testimonials",
  CONTACTUS: "Contact Us",
  // BLOG: "Blog",
};

export const useStore = create(
  devtools((set, get) => ({
    dispatch: (args) => set((state) => reducer(state, args)),
    user: {
      username: "todosadmin",
      password: "1234",
      token: "",
    },
    messages: [],
    toast: {
      message: "",
      statusCode: 0,
    },
    paths: [
      {
        displayName: pathTitles.HOME,
        path: "/",
        header: true,
        footer: true,
        dropDown: false,
      },
      {
        displayName: pathTitles.TRAINING,
        path: "/training",
        header: false,
        footer: true,
        parentDrop: "train",
        dropDown: false,
      },
      {
        displayName: pathTitles.ALLTRAININGPROGRAMS,
        path: "/training/all",
        header: true,
        footer: false,
        dropDown: "train",
      },
      {
        displayName: pathTitles.PRIVATELESSONS,
        path: "/training/private",
        header: true,
        footer: false,
        dropDown: "train",
      },
      {
        displayName: pathTitles.DAYTRAINING,
        path: "/training/day-training",
        header: true,
        footer: false,
        dropDown: "train",
      },
      {
        displayName: pathTitles.BOARDANDTRAIN,
        path: "/training/board-and-train",
        header: true,
        footer: false,
        parentDrop: "boarding",
        dropDown: "train",
      },
      {
        displayName: pathTitles.BOARDANDTRAINADULT,
        path: "/training/board-and-train-adult",
        header: false,
        footer: false,
        dropDown: "boarding",
      },
      {
        displayName: pathTitles.BOARDANDTRAINPUPPY,
        path: "/training/board-and-train-puppy",
        header: false,
        footer: false,
        dropDown: "boarding",
      },
      {
        displayName: pathTitles.BUYATRAINEDPUPPY,
        path: "/training/buy-a-trained-puppy",
        header: true,
        footer: false,
        dropDown: "train",
      },
      {
        displayName: pathTitles.BOARDING,
        path: "/training/boarding",
        header: false,
        footer: false,
        dropDown: false,
      },
      {
        displayName: pathTitles.MEETTHETEAM,
        path: "/meet-the-trainer",
        header: false,
        footer: true,
        parentDrop: "team",
        dropDown: false,
      },
      {
        displayName: pathTitles.MEETTHETEAM,
        path: "/meet-the-trainer",
        header: true,
        footer: false,
        dropDown: "team",
      },
      {
        displayName: pathTitles.MEETTHETRAINER,
        path: "/meet-the-trainer/justine",
        header: true,
        footer: false,
        dropDown: "team",
      },
      {
        displayName: pathTitles.MEETKYRA,
        path: "/meet-the-trainer/kyra",
        header: true,
        footer: false,
        dropDown: "team",
      },
      {
        displayName: pathTitles.MEETMUNCH,
        path: "/meet-the-trainer/munch",
        header: true,
        footer: false,
        dropDown: "team",
      },
      {
        displayName: pathTitles.MEETZETA,
        path: "/meet-the-trainer/zeta",
        header: true,
        footer: false,
        dropDown: "team",
      },
      {
        displayName: pathTitles.MEETAURORA,
        path: "/meet-the-trainer/aurora",
        header: true,
        footer: false,
        dropDown: "team",
      },
      {
        displayName: pathTitles.MEETSEELEY,
        path: "/meet-the-trainer/seeley",
        header: true,
        footer: false,
        dropDown: "team",
      },
      {
        displayName: pathTitles.TESTIMONIALS,
        path: "/testimonials",
        header: true,
        footer: true,
        dropDown: false,
      },
      {
        displayName: pathTitles.CONTACTUS,
        path: "/contact-us",
        header: true,
        footer: true,
        dropDown: false,
      },
      // {
      //   displayName: pathTitles.BLOG,
      //   path: "/blog",
      //   header: true,
      //   footer: true,
      //   dropDown: false,
      // },
    ],
    getLink: (title) => {
      const link = get().paths.find((path) => path.displayName === title).path;
      return link;
    },
    getSnippet: (str) => {
      return `${str.substring(0, 130).trim()}...`;
    },
    buildServices: (service) => {
      return {
        title: get().trainingDetails[service].title,
        info: get().getSnippet(get().trainingDetails[service].body.info),
        link: get().getLink(pathTitles[service]),
      };
    },
    addService: (obj, service, value) => {
      set((state) => ({
        trainingDetails: {
          ...state.trainingDetails,
          [obj]: {
            ...state.trainingDetails[obj],
            body: {
              ...state.trainingDetails[obj].body,
              otherServices: {
                ...state.trainingDetails[obj].body.otherServices,
                [service]: get().buildServices(value),
              },
            },
          },
        },
      }));
    },
    checkServices: (obj) => {
      for (const type in obj) {
        if ("otherServices" in obj[type].body) {
          for (const service in obj[type].body.otherServices) {
            let value = obj[type].body.otherServices[service];
            if (typeof value == "string") {
              get().addService(type, service, value);
            }
          }
        }
      }
      set({
        loading: false,
      });
    },
    loading: true,
    setLoading: (load) => {
      set({
        loading: load,
      });
    },
    trainingDetails: {
      ALLTRAININGPROGRAMS: {
        body: {
          title:
            "We offer an exceptional training experience for puppies and dogs.",
          info: "Todo’s Translator is here to provide an enjoyable and effective training experience for both you and your dog.   Choose in home lessons alone to teach you how to train your dog yourself or get extra help from Todo’s owner and trainer, Justine, through Day Training or Board and Train.   Justine is here to help you learn how to have a more peaceful relationship with your dog.",
          image: "trainingAll",
          imageCaption:
            "Grace and friends practice holding a down while Justine cooks dinner.",
          otherServices: {
            privateLessons: "PRIVATELESSONS",
            dayTraining: "DAYTRAINING",
            boardAndTrain: "BOARDANDTRAIN",
            buyAPuppy: "BUYATRAINEDPUPPY",
          },
          moreInfo: [
            "How to start? Contact us to schedule a non-committal Consultation ($40) to make a Training Plan specific to your situation and goals.",
          ],
        },
      },
      PRIVATELESSONS: {
        title: pathTitles.PRIVATELESSONS,
        body: {
          title:
            "Give your dog the best experience with our private dog training program.",
          info: "Private Lessons are to educate YOU on how to work with your dog on your specific goals at home, or wherever you need to practice. Lessons are a necessity to success, no matter the service you choose. Lessons are so you understand how to train your dog.",
          image: "privateLessons",
          imageCaption:
            "Implementing impluse control at a dog friendly restaurant in Broad Ripple.",
          moreInfo: [
            "All programs offered will include Lessons for the owners to learn how to work with their dog in their home or where the problem behaviors are occurring.  With years of experience and hundreds of dogs trained Justine is able to offer you individualized solutions to your dog related problems.",
            "During lessons we will focus on teaching you how to handle your dog in a way that reinforces wanted behaviors and makes unwanted behaviors less successful to your dog (and therefore less likely to be repeated).",
            "Keep in mind that dog training is a process. It is daily routine and proper communication with our dogs. It is hard work creating a relationship built on trust and fellowship. Just like everything in life the more you put into it the more you get out of it. Do your best to make training your dog a lifestyle.",
            "Private lessons are a great option for Justine to teach you how to train your dog. Because a large majority of dog training is repetition it is up to you to do the reps. Just like going to the gym, you must work on a regular basis with your dog to attain your goals.",
            "To be sure that you get the most out of your training sessions please make your priorities clear so that the focus is on what is important to you. Justine’s goal is to leave you with enough understanding of how to train dogs that you become her “apprentice dog trainers”.",
          ],
        },
      },
      BOARDANDTRAIN: {
        title: pathTitles.BOARDANDTRAIN,
        body: {
          title:
            "Accepting all breeds and ages for in depth training inside the Trainer’s home.",
          info: `During a Board and Train through Todo’s Translator your dog goes to live with
          owner and trainer, Justine in her home for a faster paced, more immersive solution. Your dog
          will be practicing calm polite behavior every minute of the day through training as a lifestyle.
          They also will be treated as a member of the family with lots of love, cuddles and play time in
          the half acre yard with other dogs. This training option is so successful not because it is similar
          to real life but also because it coincides with lessons for the owners to learn their dog or puppy’s
          new skills.`,
          image: "boardAndTrain",
          imageCaption:
            "Cooper, in Justine's living room, practicing impulse control around cats.",
          moreInfo: [
            "Board and Train is the fastest, most effective way to see a dramatic change in your dog’s behavior.",
          ],
          otherServices: {
            puppyTraining: "BOARDANDTRAINPUPPY",
            boardAndTrain: "BOARDANDTRAINADULT",
          },
        },
      },
      BOARDANDTRAINADULT: {
        title: pathTitles.BOARDANDTRAINADULT,
        body: {
          title: "The most effective ways to reach your goals quickly.",
          info: `The most effective, fast paced option available to train your dog is Board and Train. Your dog
          would come to live with Justine, owner &amp; trainer, and her family in their home for the most
          intensive part of its training. Every dog who comes to stay is treated as one of the pack. While
          there is a lot of training and work that gets done there is also plenty of snuggles, play time and
          rest. Enjoy peace of mind knowing that Justine will do all the hard work and your dog will be
          happy and well cared for.

          Board and Train in Justine’s home is so effective because the dogs are training as a lifestyle.
          Consistency is all day every day. Rules never change. And every skill learned is implemented
          in real life settings. Lessons for owners are included in every board and train program.`,
          image: "btAdult",
          imageCaption:
            "Jax on walk with Justine, working on reactivity towards people, leaf blowing, cars, and dogs.",
          moreInfo: [
            "Obedience training comes with a number of benefits for both you and your dog. Many public places allow well-behaved dogs, including stores, public beaches, and outdoor event venues. You might also take a trained dog on a hike or swimming in a public lake. When properly trained, a dog can participate in many of the activities that you and your family enjoy doing. Obedience training can also help to keep your dog safe, as they’ll respond to your commands and exhibit good behavior in a wide range of circumstances and situations.",
            "You can choose from several obedience training options, depending on the age of your dog and what you want to get out of the experience. We offer several board-and-train programs, which involve leaving your dog with us for several weeks for boarding and professional training. You can also schedule your dog for one-on-one private training or in-home training lessons. Check out all our different programs to see what you feel is best for your dog!",
          ],
          otherServices: {
            puppyTraining: "BOARDANDTRAINPUPPY",
            privateLessons: "PRIVATELESSONS",
          },
        },
      },
      BOARDANDTRAINPUPPY: {
        title: pathTitles.BOARDANDTRAINPUPPY,
        body: {
          title:
            "Our puppy training will get your puppy started out on the right paw.",
          info: `It’s never too soon to start training your puppy. Puppies are already learning from
          us the moment they come home; it’s invaluable to make sure your puppy is learning the right
          lessons from the get go.

          During puppy board and train your puppy will be learning all the needed life skills to live up to
their new best friend status. Puppy will work on crate training, potty training, impulse control
(such as not jumping, not chewing, not biting, not eating everything in sight…) and crucial
socialization skills.
If you want your puppy to have the best foundation possible board and train with Justine is the
way to go.
Length of your puppy’s board and train depends on how fluently you would like your puppy to
know their new skills.`,
          image: "btPuppy",
          imageCaption: "Rowan taking a nap on Justine's back porch.",
          moreInfo: [
            "You have three choices for puppy training. Our puppy primer program is $700 per week with you choosing how long your puppy stays with us. The second option is our polite puppy package where you learn how to train your puppy. You receive six 30-minute private lessons and intensive training for $650 a week (minimum of 4 weeks to book). We discuss all things puppy: potty training, crate training, socialization, puppy nipping, and more. We also get a foundational start on all your basic obedience commands. This is the perfect way to learn how to train your dog and get them started out on the right paw! The third option is the perfect puppy program, where Todo's does all of the work for you. For $600 a week, (minimum 6 weeks to book), and 8 in home lessons this program is key to having a dog that responds to commands no matter the situation.",
            "As your puppy grows, you can shift from puppy training to our other dog training offerings. Once again, you can choose from the board and train experience or as additional one-on-one lessons that go over the next phase of dog training, namely basic obedience. Let us know if you have any questions about our puppy training or other services.",
          ],
          otherServices: {
            privateLessons: "PRIVATELESSONS",
            boardAndTrain: "BOARDANDTRAINADULT",
          },
        },
      },
      BUYATRAINEDPUPPY: {
        title: pathTitles.BUYATRAINEDPUPPY,
        body: {
          title:
            "A Trained Puppy from Todo’s Translator has the knowledge and experience necessary to behave as we expect a ‘good dog’ to behave.",
          info: `The puppy will learn how to live a cooperative life with humans; what is allowed, what isn’t, and how to be an all around family dog. Each puppy is handpicked and hand raised by Justine in her home with her dog pack.  Justine starts by searching for the right breeder.  Many aspects of a dog’s overall health and temperament are determined by genetics and early experiences so choosing the right breeder is vital. Once she chooses the breeder she makes sure that she chooses the right litter and right pup.  Justine checks the health and temperament of both parents, then watch how the puppies interact with one another to be sure she brings the perfect personality home.

          Once the right puppy has been chosen it leaves the breeder and goes straight to Justine to start
it’s life with the perfect training foundation. Potty training, crate training, socializing, impulse
control (such as not jumping, biting or chewing) and basic obedience are all included with a Buy
A Trained Puppy.

If you would like the joy of a new family member but are unsure about your capabilities to raise
a puppy consider contacting Justine for help.`,
          image: "buyATrainedPuppy",
          imageCaption:
            "Tinker, Rukus, and Grace - all sold trained puppies - learned what swimming was and how to do it!",
          otherServices: {
            buyAPupWeb: {
              title: "Ready to find your new family member?",
              info: "The thought of raising a new puppy on your own is overwhelming. You want a dog that will fit perfectly with your lifestyle. Your life...",
              link: "http://www.buyatrainedpuppy.com/",
            },
            puppyTraining: "BOARDANDTRAINPUPPY",
          },
        },
      },
      DAYTRAINING: {
        title: pathTitles.DAYTRAINING,
        body: {
          title:
            "Day Training is an opportunity for Justine to take on some of the repetition for you.",
          info: "Day Training is for your dog to spend time with Justine one on one – no owners.  Justine spends up to 3 hours per session with your dog working on specific goals.  These sessions are great for extra repetition, to troubleshoot problems, or to introduce tools.  Justine may work with your dog in your home or out in public.  Day Training is always followed by a lesson the next day to teach you what your dog has learned.",
          image: "dayTraining",
          imageCaption:
            "Sadie and Cooper are learning to wait at the door to the garage, even when it's left open.",
          moreInfo: [
            "We will train, play, take a break, repeat. Even when he’s taking a break he will still be learning how to be a relaxed, calm guy. Training will occur in whatever environment is conducive to your dog’s learning style. This can include in the car, at Justine's home, in her half acre fenced yard, on walks, on the Monon Trail, at dog friendly restaurants, pet stores, or in your home. Whichever environment works best for your dog at that point in his education is where we will practice. The activities your dog and Justine do will reflect your lifestyle and training goals and will therefore be more productive in the long run.",
          ],
          otherServices: {
            buyAPuppy: "BUYATRAINEDPUPPY",
            privateLessons: "PRIVATELESSONS",
          },
        },
      },
      BOARDING: {
        displayName: pathTitles.BOARDING,
        body: {
          title:
            "We offer a different kind of dog boarding experience we think you and your dog will love.",
          info: "There are many dog boarding facilities out there that focus only on providing a safe place for your dog to be when you have to go out of town on business or vacation. Here at Sublime K9 Training & Boarding, our primary focus is on dog training where your dog stays with us while we get them trained for you. Dog boarding is part of the board & train process in this case. However, we do offer regular dog boarding when space allows.",
          image: "boardAndTrain",
          imageCaption:
            "Soo and soo in Justine's living room, practicing positive associations with cats and impulse control in real life environments.",
          moreInfo: [
            "Our dog boarding experience includes as many let outs as your dog needs, and if your dog is social, it also includes plenty of supervised playtime with other dogs! You must pack your dog’s own food. All dogs must be current on all vaccines, including kennel cough.​",
            "Our kennel runs are indoor only and 4 ft by 4 ft. Each has its own raised dog bed for comfort, and blankets are welcome as long as your dog does not chew them. We chose to do indoor only kennels because giving your dog the outdoor option can create a bad habit of allowing your dog to use the bathroom inside the kennel. Our boarded dogs spend most of their time outside of their kennel, socializing and playing. Kennel time is for down time, feeding times, and bed time! We want your dog’s stress levels and energy levels low, so we strive to make your dog’s boarding experience fun!",
          ],
          otherServices: {
            privateLessons: "PRIVATELESSONS",
            boardAndTrain: "BOARDANDTRAIN",
          },
        },
      },
    },
    aboutDetails: {
      THETEAM: {
        title: pathTitles.MEETTHETEAM,
        body: {
          title: "From our family to yours.",
          info: "Justine Ramey is the owner and trainer at Todo’s Translator. Justine opened Todo’s for business in 2015 to help owners to have more peaceful lives with their dogs.  She wants all dog owners to understand how to have a life with their dogs that is enjoyable and fullfilling.  Justine is a member of the Association of Pet Dog Trainers and the International Assosiation of Canine Professionals.  She is constantly looking to grow and expand her knowledge base through relationships with other trainers, conferences and workshops.",
          image: "theTeam",
          imageCaption:
            "Kyra (left) and Justine (right) with 5 sold Buy A Trained Puppy puppies.",
          moreInfo: [
            "Justine wants to teach you how to have a dog that works for you because he ENJOYS it, respects you and values your relationship.",
            "Justine lives with her son, Foster, her niece Sierra, and their pack. They have Munch, the Dachshund, the pup who trained Justine, Zeta, the Pitbull Mix, who is chatty and loves all humans, and Aurora, the Cane Corso Mastiff, who's happiness can be seen in her hips.",
          ],
          otherMembers: {
            KYRA: {
              title: "Meet Kyra",
              info: "In early 2021, Kyra came to work for Todo's Translator. Kyra helps with the everyday caretaking, training, and spoiling each dog with love and affection.",
              link: "/meet-the-trainer/kyra",
              image: "kyra",
              imageCaption: "Kyra holding a sold Buy A Trained Puppy",
            },
            MUNCH: {
              title: "Meet Munch",
              info: "He is a 14 year old Dachshund, and Justine's first dog! He loves digging through the car for any forgotten snacks and telling at all birds to get out of the yard. He tries his best to run with the big dogs and usually pretends to get distracted when he can't.",
              link: "/meet-the-trainer/munch",
              image: "munch",
              imageCaption: "Munch enjoying his life of eating and napping.",
            },
            ZETA: {
              title: "Meet Zeta",
              info: "A vocal, eager to please, hard worker. Zeta doesn't make fast friends, unless they're humans! She prefers to play tug, chase, or do any command asked than jump into the dog pile. Zeta is a family favorite here and would love to show you why if you'd just lay on the floor for a second.",
              link: "/meet-the-trainer/zeta",
              image: "zeta",
              imageCaption: "Zeta is ready for Justine's next request.",
            },
            AURORA: {
              title: "Meet Aurora",
              info: "AKC registered, 2 year old, Cane Corso. Aurora naturally grew into that beautiful dog show stride. With a docked tail, she shakes her entire backend to express her happiness - preferably in your face. Her favorite activities include stealing Zeta's ball, jumping the gate Mom (Justine) just closed, and show the new kids how we do things.",
              link: "/meet-the-trainer/aurora",
              image: "aurora",
              imageCaption: "Aurora taking a rest in the sun.",
            },
            SEELEY: {
              title: "Meet Seeley",
              info: "Seeley, the Siamese cat, is an absolutely gorgeous 5 year old. While some cats are full of affection and others are aloof; Seeley is a blend of both. In true cat style, he demands attention when he wants it, lies lazily around the house when he doesn't, and comes running when he hears training sessions begin. He will even demand to go with on dog walks.",
              link: "/meet-the-trainer/seeley",
              image: "seeley",
              imageCaption: "Caption for Seeley's picture",
            },
            GUESTS: {
              title: "Guest Helpers",
              info: "Many of our board and train puppies and dogs end up helping in lessons.  This helps the client who's lesson it is as well as the helper dog.   This is an opportunity for both to learn appropriate communication (dog/dog) as well as practice obedience and impulse control around other distractions.",
              link: "",
              image: "guests",
              imageCaption:
                "Justine walking Pez, Chico, Charlie, and Bear - all of whom came to her unable to walk on a leash.",
            },
          },
        },
      },
      KYRA: {
        title: pathTitles.MEETTHETEAM,
        body: {
          title: "The Team: Kyra",
          info: "Since Kyra started working for Todo's Translator she has become part of the family.  She helps Justine with the husbandry side of having dogs, in lessons when we need another person or dog and on group outings to help train and socialize dogs.  And if you ask the dogs they'd tell you she gives the best ear scratches.  Kyra is learning a lot and we look forward to seeing how she grows with the business.",
          image: "teamkyra",
          imageCaption: "Kyra with a sold Buy A Trained Puppy puppies.",
          moreInfo: [""],
        },
      },
      JUSTINE: {
        title: pathTitles.MEETTHETEAM,
        body: {
          title: "The Team: Justine",
          info: "Justine Ramey is the owner and trainer at Todo’s Translator. She has always had a strong love for animals and for a long time believed that to work with animals she had to become a veterinarian.  Justine embarked upon that path and attended college as a pre-vet student. While the necropsies and physiology classes were fascinating, it wasn’t until she took Ethology (the study of Animal Behavior) that she found her true passion. Justine volunteered at animal shelters, and worked in pet shops. She got a job training dogs at PetsMart. Her life led her to Indianapolis where she got a job as the trainer at a boarding facility. It was here that she saw so many loving owners struggling with seemingly out of control dogs. Justine started by helping some of the boarding facility clients by doing more in depth training. She enrolled in training seminars and conferences, and soon after opened Todo's Translator.",
          image: "teamjustine",
          imageCaption:
            "Aurora and Zeta showing Justine a little appreciation.",
          moreInfo: [
            "Justine lives with her son, Foster, her niece Sierra, and their pack. They have Munch, the Dachshund, the pup who trained Justine, Zeta, the Pitbull Mix, who is chatty and loves all humans, and Aurora, the Cane Corso Mastiff, who's happiness can be seen in her hips.",
            "Justine opened Todo’s Translator 5 years ago to help more dogs stay in forever homes, to help owners to fully enjoy their canine companions and to further the bond we share with our four-legged family members.",
            "Mostly, Justine wants you to have a stress-free relationship with your pooch!",
            "Justine chooses to do one on one type sessions because the success of the work depends on several factors; personality of dog and owner, what training method works best for the dog as an individual, what energy level the dog is, what motivates the dog and how motivated the owner is to make a change.  One on one sessions allow Justine to get to know your dog as an individual and to learn exactly what may work best for your personal situation.",
            "Methods:   No Force, Just Fun!",
            "Justine prides herself on using non-forceful training methods.  She uses a combination of positive reinforcement, clicker training and communicating with dogs by using and reading body language.  Her goal is to see a relationship based on mutual respect between all members of your family; two and four legged. This requires using the appropriate training tools properly, clear ‘house rules’, patience and most importantly consistency.",
            "She wants to teach you how to have a dog that wants to work for you because he enjoys it and respects you.  It’s all about communication!",
          ],
        },
      },
      MUNCH: {
        title: pathTitles.MEETTHETEAM,
        body: {
          title: "The Pack: The Story of Munch",
          info: "Munch is the one who started it all.  Way back in 2007 Justine got her first dog of her own; Munch the dachshund.  Justine was a pre-vet student in college. Munch's puppy class coincided with a class she took on Ethology (the study of animal behavior) and she realized behavior was her jam, not medicine.  And their journey began.",
          image: "teammunch",
          imageCaption: "Munch after a fresh haircut before Easter.",
          moreInfo: [
            "  Nowadays Munch mostly keeps to himself as far as other dogs are concerned.  He does his thing -  a few laps around the yard then holds the couch down for us.  Munch is a treasure and Justine is grateful for his companionship along the way.",
          ],
        },
      },
      ZETA: {
        title: pathTitles.MEETTHETEAM,
        body: {
          title: "The Pack: The Story of Zeta",
          info: "Zeta came to join our family in 2016.  She is some kind of mystery pitbull mutt that was given to us when we lived in Fountain Square.  Zeta has always been a tenacious and smart little thing.",
          image: "teamzeta",
          imageCaption: "Zeta races to greet the photographer.",
          moreInfo: [
            "Zeta prefers to not be with guest dogs. She has her small social group and that's enough for her and we are ok with that.  Zeta is one of the best dogs Justine has ever known and is a joy to train and share life with.",
          ],
        },
      },
      AURORA: {
        title: pathTitles.MEETTHETEAM,
        body: {
          title: "The Pack: The Story of Aurora",
          info: "Aurora is the baby of the family.  She was born in 2020 from a local Cane Corso breeder.  Justine temperament tested the litters of 14 puppies over several weeks to choose the right work dog.  When reviewing the video of the tests Justine noticed that one puppy in particular was always engaging and interacting with her.  Justine believes Aurora chose her.",
          image: "teamaurora",
          imageCaption:
            "Aurora sits in Justine's lap, loving every minute of attention.",
          moreInfo: [
            "A week after picking Aurora up from the breeder the country went into quarantine for covid-19.  Even as a dog trainer it's difficult to socialize a guardian breed when no one is around to socialize with.   So, Aurora is our covid puppy but still a fabulous work dog.  She has a bit more work to do (don't we all) to be where Justine wants her but we nickname her 'mama' because she is such a good mama dog to all the guests.",
          ],
        },
      },
      SEELEY: {
        title: pathTitles.MEETTHETEAM,
        body: {
          title: "The Pack: The Story of Seeley",
          info: "Seeley Booth, named for the protagonist on the TV show Bones because he is so handsome, is our resident siamese cat.",
          image: "teamseeley",
          imageCaption:
            "Seeley frequently joins training sessions to earn snacks.",
          moreInfo: [
            "Seeley can't seem to decide if he is a cat or a dog.  When he hears dog training happening he shows up.  Seeley can come when called, sit, paw, spin, get into heel position and is working on weaving between legs.  He is also very good at socializing dogs to cats because he is unperturbed by them and will also put them in their place if he needs to.",
          ],
        },
      },
    },
    testimonials: [
      {
        clientName: "Megan",
        location: "Geist, IN",
        comment:
          "Justine I have to tell you how great it is to walk Snickers and not feel embarrassed!  Remember the neighbor that had made so many complaints to the Neighborhood Association? Well yesterday she came up and pet him and told me that I must’ve traded dogs with someone!  I can’t believe it!",
      },
      {
        clientName: "Janel",
        location: "Fishers, IN",
        comment:
          "Bear has been a completely new dog since he came back from his board and train!  Thanks for taking the time to make sure we knew how to handle him just like you can.",
      },
      {
        clientName: "Joe",
        location: "Indianpolis, IN",
        comment:
          "I would highly recommend hiring Todo’s Translator to help train your dog. Justine helped us with our puppy (we started at 5 months) to stop nipping, barking, pulling on leash and pooing inside.  She helped us to understand how our puppy learned those habits and how to break them.  Whenever I have a friend that gets a puppy I send them to her!",
      },
      {
        clientName: "Carl",
        location: "Carmel, IN",
        comment:
          "Justine, I am amazed with the improvements Callie has made since we first started working together.  She still sometimes gets overexcited but now I know how to handle her and help her to calm herself.  Thank you!",
      },
      {
        clientName: "Josh",
        location: "Noblesville, IN",
        comment:
          "I was skeptical when we first started working together.  I didn’t really think that {Nothing in Life is Free} would help Charlie respect me but it does!  It’s only been a few months since you suggested I make him work for everything that he gets from me and he now asks politely from anything he wants from me.  He even asks politely at the pet stores too.  Thanks for helping it all come together.",
      },
      {
        clientName: "Marge",
        location: "Indianpolis, IN",
        comment:
          "Justine impressed me immediately when she showed up exactly on time.  The week before PetPeople referred me to Justine I had scheduled an appointment with another trainer.  That trainer never showed up.  Honestly, I’m glad the first trainer didn’t bother coming because I never would have found Justine otherwise.  A few months later when I experienced health issues Justine sent me the kindest card and helped me to take care of my dog when I was too ill to do so.  I never would have expected to find a friend when I hired a dog trainer!",
      },
    ],
    getWindow: () => {
      set({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    width: 0,
    height: 0,
    googleReviews: [
      {
        profileImage:
          "https://lh3.googleusercontent.com/a-/AOh14GjgRWGvDHyUc2_VrRc8eW1iWx8BB0kOdxSNMV2g2A=s40-c-c0x00000000-cc-rp-mo-br100",
        reviewerName: "Kimmie",
        stars: 5,
        positive: true,
        keywords:
          "Professionalism, Quality, Reliability, Responsiveness, Value",
        review:
          "Justine is the dog whisperer! Our 8 month old Aussie went to training with her for 4 weeks. Prior to training, he chewed everything in sight and jumped on people none stop. He had so much energy and we needed help figuring out how to help channel that energy and get him to behave. He was out of control! Since being home, he has not chewed anything, he tolerates new people and has improved 100000%,& he actually is just chill. We can finally enjoy our dog! This training has been a game changer. We appreciate everything and will always refer Justine! Hands down a miracle worker for our Aussie! *I think Jax misses you every now and then",
        images: [
          "https://lh5.googleusercontent.com/p/AF1QipMqmkTjNYPmX0q-j8JKx396eAlQt7umjMnZd4tr=w100-h100-p-n-k-no",
          "https://lh5.googleusercontent.com/p/AF1QipOCJvZodDvbh0Pqntivgd0hFizYnCtWUGUMKN5y=w100-h100-p-n-k-no",
          "https://lh5.googleusercontent.com/p/AF1QipMxgSwHaSVwjTTKzSnUq_6ieI1mIo8OUuWDvmRK=w100-h100-p-n-k-no",
        ],
      },
      {
        profileImage: "",
        reviewerName: "Pamela",
        stars: 5,
        positive: true,
        keywords: "",
        review:
          "Justine did an amazing job with my new puppy. He was stubborn but she was able to get him to be a good dog.",
        images: [
          "https://lh5.googleusercontent.com/p/AF1QipPzf5YT4uxiySn5RVO6MvwWcvj-jrGfu5rJMC99=w100-h100-p-n-k-no",
        ],
      },
      {
        profileImage:
          "https://lh3.googleusercontent.com/a-/AFdZucp9MjOpBkWE5k1hNacc49y-iJsaD6MGkyZpDUqR_Q=w60-h60-p-rp-mo-ba2-br100",
        reviewerName: "Ashley",
        stars: 5,
        positive: true,
        keywords: "Professionalism, Quality, Reliability",
        review:
          "Justine is AMAZING!!! She did miracles with my lab and continues to support me with tips and tricks even almost a year later!!! You will NOT be disappointed!",
        images: [],
      },
      {
        profileImage: "",
        reviewerName: "Angie",
        stars: 5,
        positive: true,
        keywords:
          "Professionalism, Quality, Reliability, Responsiveness, Value",
        review:
          "Justine did an amazing job with our puppy. She not only trains your dog but also trains you so that you know what to do. Highly recommend!!",
        images: [],
      },
      {
        profileImage: "",
        reviewerName: "Sophia",
        stars: 5,
        positive: true,
        keywords:
          "Professionalism, Quality, Reliability, Responsiveness, Value",
        review:
          "Justine is amazing! She is always prepared, on time and patient with our sweet pup. We are proud of the progress our dog continues to make and we owe it all to Justine. We highly recommend her!!",
        images: [
          "https://lh5.googleusercontent.com/p/AF1QipMZDGcLWZs08i0nqEN7Qb2Hpj5UqNTMlneL7vO_=w100-h100-p-n-k-no",
        ],
      },
      {
        profileImage:
          "https://lh3.googleusercontent.com/a-/AFdZucpl8xjsLL9CxaCN057fjFcKeLc_ZKAf59_YHaH-iP4=w60-h60-p-rp-mo-br100",
        reviewerName: "Ryan",
        stars: 5,
        positive: true,
        keywords: "Professionalism, Quality",
        review:
          "Love Justine! She is fluent on multiple training methods and ideologies and will work with you to find the best plan of action for your dog and the results you are looking for. Very personable as well and easy to get along with.",
        images: [],
      },
      {
        profileImage: "",
        reviewerName: "Allisa",
        stars: 5,
        positive: true,
        keywords: "Professionalism, Quality, Reliability, Responsiveness",
        review:
          "Super down to earth, knowledable and understanding trainer. I've worked with some that are harsh and almost rude, but shes compassionate and listens!  She worked with my pup Koda for his leash reactivity for 2 lessons an hr each and I could see a dramatic difference in just two lessons. Now its my time to take what she taught us and practice!  Would highly recommend!",
        images: [],
      },
      {
        profileImage: "",
        reviewerName: "Christina",
        stars: 5,
        positive: true,
        keywords:
          "Professionalism, Quality, Reliability, Responsiveness, Value",
        review:
          "My dog, Elvis (75 lb. boxer/lab), pulled on his harness for walks. He was out-of-control jumping on us and barking at guests during visits. He had high-anxiety and was (sometimes still is) very stubborn. Todo’s not only trained Elvis to stop pulling on a walk, she trained Elvis to walk next to me on a gentle collar. He now ignores distractions by focusing on me. He doesn’t jump on us and doesn’t bark insanely at visitors. It’s a different dog. Elvis is happier, has less anxiety, and has more control knowing the cues and what action to take. His routine gives him peace. We are no longer frustrated with our loving dog. Our entire house is a better place. Thank you for helping all of us - humans and pups!",
        images: [],
      },
    ],
    inHomePrograms: [
      {
        programName: "Simply Manners Program",
        definition:
          "This program covers your bases to have a calm, polite four legged family member. If you’re looking for your pooch to be more attentive, to listen better and to understand basic commands this is the package for you! You will be taught the initial stages of most basic obedience commands, the key elements of how dogs learn and how to provide your pooch with routine and structure.",
        included: [
          "3 Private In Home Training Sessions at a time convenient for you",
          "Learn how to earn the respect of your dog using Force-Free methods",
          "Training Equipment for each session",
          "Sit, Down, Stay, Wait, Gentle, Leave It, Drop It, Touch",
          "Briefly cover common problem behaviors; housebreaking, jumping up, barking, etc.",
          "Handouts, related articles and weekly homework",
          "Graduation Certificate: Basic Obedience",
          "Access to the Trainer via Phone and E-mail",
        ],
        cost: "$350",
        expectations: [
          "Basic understanding of why dogs do what they dog and how they learn",
          "Basic understanding of how to solve/prevent common problem behaviors",
          "Dog will have basic knowledge of obedience skills",
        ],
        image: "program_simply_manners",
      },
      {
        programName: "Simply Manners Plus Leash Walking Program",
        definition:
          "Does your dog need to learn manners both inside and on a leash? If so, this may be the program for you. You will be taught the fundamental skills to train your dog the early stages of basic manners and loose leash walking.",
        included: [
          "6 One Hour Private In Home Lessons",
          "1 Day Training Lesson",
          "Learn how to earn the respect of your dog using Force-Free methods",
          "Sit, Down, Stay, Wait, Gentle, Leave It, Drop It, Touch, Come + Loose Leash Walking",
          "Briefly cover common problem behaviors; housebreaking, jumping up, barking, etc.",
          "Handouts, related articles and weekly homework",
          "Photo of you and your pooch",
          "5% Discount on future Services including boarding",
          "Graduation Certificate: Intermediate Obedience",
          "Access to the Trainer via Phone and E-mail",
        ],
        cost: "$790",
        expectations: [
          "Understand why dogs do what they dog and how they learn",
          "Understand how to solve/prevent common problem behaviors",
          "Reduction in problem behaviors",
          "Dog will have basic knowledge of obedience skills",
          "Early stages of recall (coming when called)",
          "Human will understand how to begin teaching a loose leash walk",
          "Dog will have the beginnings of a loose leash walk",
        ],
        image: "program_simply_manners_plus_leash",
      },
      {
        programName: "Ultimate Family Dog",
        definition:
          "This is the package for the dog owners who want to be involved in their dog’s education and take that education to the next level. Includes Simply Manners plus Leash Walking. Now that your pooch knows what each command means how do we ensure that he performs every time you ask, no matter what? Justine will be happy to teach you!",
        included: [
          "8 One Hour Private In Home Lessons",
          "2 Day Training Sessions",
          "FREE Follow Up Session upon completion for Real World Application",
          "Training Equipment (including Head Halter or Front Clip Harness)",
          "Learn how to earn the respect of your dog using Force-Free methods",
          "Sit, Down, Stay, Wait, Gentle, Leave It, Drop It, Touch, Come, Loose Leash Walking, Recall (come when called) and a few tricks!",
          "Learn how to generalize these behaviors so your dog performs in all situations.",
          "Handouts, related articles and weekly homework",
          "CGC, or Canine Good Citizen, testing can be added on for $25",
          "Photo of you and your pooch",
          "10% Discount on future Services including boarding",
          "Graduation Certificate: Advanced Obedience",
          "Access to the Trainer via Phone and E-mail",
        ],
        cost: "$1095",
        expectations: [
          "Understand why dogs do what they dog and how they learn",
          "Understand how to solve/prevent common problem behaviors",
          "Reduction, possible elimination, in problem behaviors",
          "Dog and human will understand how to work as a team and use their obedience skills in most scenarios",
          "Human will understand how to teach a loose leash walk",
          "Dog will loose leash walk",
        ],
        image: "program_ultimate_family_dog",
      },
      {
        programName: "Feisty Fido - Behavioral Modification Program",
        definition:
          "This is the program for dogs who have issues beyond what basic manners can repair (shy, overexcited and reactive dogs) and the owners who want to actively help their dog to recovery. We will begin by learning manners to help your pooch learn to focus and work for what he wants. After you and your dog have learned basic commands and how to work as a team Justine will tailor make a plan to address your specific needs. Frequently this includes teaching you Behavior Adjustment Training and/or Counter Conditioning/Desensitization. This program may or may not include leash walking depending on your dog’s specific needs.",
        included: [
          "6 One Hour Private In Home Lessons",
          "4 Day Training Sessions",
          "2 FREE Follow Up Sessions upon completion for Real World Application",
          "Training Equipment (including Head Halter or Front Clip Harness)",
          "Learn how to earn the respect of your dog using Force-Free methods",
          "Basic Obedience commands - depend on your needs - Focus wil be on teaching your pooch calm, polite behavior.",
          "Handouts, related articles and weekly homework",
          "Photo of you and your pooch",
          "10% Discount on future Services including boarding",
          "Graduation Certificate: Life Skills",
          "Access to the Trainer via Phone and E-mail",
        ],
        cost: "$1275",
        expectations: [
          "NOTE: Depending on your particular situation your dog may require more or fewer sessions.",
        ],
        image: "program_fiesty_fido",
      },
      {
        programName: "Unlimited Lifetime Lessons Program",
        definition:
          "If you have serious long term goals to attain this is your package! This package can be used to raise a puppy to adulthood, address serious behavioral issues or to train your dog to go off leash. Don't restrict yourself with limited access to the trainer - go unlimited! After buying this package all you'll need is the dog! Good for the life of your dog(s).",
        included: [
          "Unlimited Private In Home Lessons",
          "Up to 5 Day Training Sessions",
          "All necessary Training Equipment (including Long Line and Leash Walking Equipment)",
          "Unlimited Obedience commands - If you can think it, we can do it! - Unlimited tricks!",
          "20% Discount on future Services including boarding",
          "Unlimted Graduation Certificates",
          "Unlimited Access to the Trainer",
        ],
        cost: "$2750",
        expectations: [
          "ALL of your training goals are met",
          "ALL refresher courses included for the life of your dog",
          "Anything you can dream we can accomplish together!",
        ],
        image: "program_unlimited",
      },
    ],
    btPuppyPrograms: [
      {
        programName: "Puppy Primer Program",
        definition: `This program includes two weeks of intensive Board and Train to jumpstart your puppy’s
          education. Justine will work with your dog to teach basic manners, develop appropriate
          behaviors and discourage common problem behaviors. This is the program for you if you need a
          little help to get started on your dog’s training. This program will have your puppy jumpstarted
          on housebreaking, socialization and basic manners. Again, it takes a minimum of 21 days to form
          a habit. So, while this program will be infinitely beneficial to your puppy’s social and mental
          development you (the owner) will need the continue working with your puppy to form the habit.`,
        included: [
          "2 Weeks intensive training in the trainer’s home with her pack",
          "4 In Home Private Training Sessions – at pick up, midpoint, drop off and one follow up",
          "Homework and relevant articles for each training session",
          "Picture and Video updates during your pup’s stay",
          "Bath and Nail Trim",
          "Graduation Certificate: Puppy Class 100",
          "Access to the trainer via e-mail 24 hours a day (phone for immediate needs)",
        ],
        cost: "$1400",
        expectations: [
          "Reduction in problem behaviors such as jumping, barking, nipping and chewing.",
          "Pup has fundamental understanding of sit, down, wait, stay, leave it, drop it, gentle, off under low distractions.",
          "Pup understands ‘game show buzzer’ (noise that means no) and clicker training.",
          "Pup will understand that he is supposed to potty outside – keep in mind puppies aren’t reliably housetrained until 6 months old so keep supervision tight.",
          "Pup will have a minimum of half of its recommended socialization completed.",
          "Pup will be comfortable with body handling similar to what he may experience by a veterinarian or a groomer.",
          "Pup will be introduced to and comfortable with the crate.",
        ],
        image: "program_bt_puppy_primer",
      },
      {
        programName: "Polite Puppy Program",
        definition: `This program is the primer program plus extras. Your puppy comes to live with me and I
          will teach him basic manners, loose leash walking, housetraining, crate training, and prevention
          of common unwanted behaviors; all of the essentials. Once Justine has taught your pup basic
          commands she will proof them (make the behavior more solid) by practicing the behaviors with
          added distractions in several settings such as pet stores, hiking trails, dog friendly businesses and
          restaurants, farmer’s markets etc. By the end of the Polite Puppy Program your dog will be able
          to perform basic obedience commands in most common settings under mild distractions.`,
        included: [
          "4 Weeks intensive training in the trainer’s home with her pack",
          "6 In Home Private Training Sessions – at pick up, one per week (3), at drop off & follow up",
          "Handouts, homework and relevant articles for each training session",
          "All traing Equipment - include head halter or front clip harness for leash walking",
          "Picture and Video updates during your pup’s stay",
          "Bath and Nail Trim",
          "Graduation Certificate: Puppy Manners 101 & 102",
          "Access to the trainer via e-mail 24 hours a day (phone for immediate needs)",
        ],
        cost: "$2600",
        expectations: [
          "Significant reduction in problem behaviors such as jumping on people, begging, barking and nipping, pulling on leash and pottying inside (elimination depending on severity and age of pup).",
          "Pup has solid understanding of sit, down, wait, stay, leave it, drop it, gentle, settle (go to place/mat), touch in high distraction situations.",
          "Solid understanding of ‘come’ in most mildly distracting scenarios.",
          "Ability to loose leash walk.",
          "Bonus : One additional trick.",
          "Pup understands ‘game show buzzer’ (noise that means no) and clicker training.",
          "Pup is 100% potty trained, understands that he is supposed to potty outside, and will ring bell to indicate – keep in mind puppies aren’t reliably housetrained until 6 months old so keep supervision tight.",
          "Pup is completely socialized in several safe environments.",
          "Pup will be comfortable with body handling similar to what he may experience by a veterinarian or a groomer.",
          "Pup is 100% crate trained.",
          "Pup learns to pay attention and focus.",
        ],
        image: "program_bt_polite_puppy",
      },
      {
        programName: "Perfect Puppy Program",
        definition: `Would you like having ALL of your dog’s training completed as quickly and efficiently as possible?
          If so, this is the program for you. Justine will do all of the work training your dog and will teach you all of
          the necessary information by meeting once per week for the duration of the puppy’s stay. All of the
          learned behaviors will be proofed (made solid) by working in as many high distraction areas as
          possible. The key to having a dog that responds to commands in any situation is thousands of
          repetitions in every situation imaginable. Allow me to perform all of the tedious repetition with your
          dog while you relax and enjoy the peace of mind knowing you will have an obedient well-mannered
          dog.`,
        included: [
          "6 Weeks intensive training in the trainer’s home with her pack",
          "8 In Home Private Training Sessions – at pick up, at the completion of each week of B&T (5), at drop off and follow up",
          "Handouts, homework and relevant articles for each training session",
          "All traing Equipment - include head halter or front clip harness for leash walking",
          "Picture and Video updates during your pup’s stay",
          "Bath and Nail Trim",
          "Graduation Certificate: Obedience 210",
          "Access to the trainer via e-mail 24 hours a day (phone for immediate needs)",
        ],
        cost: "$3600",
        expectations: [
          "Elimination in problem behaviors such as jumping on people, begging, barking and nipping, pulling on leash and pottying inside.",
          "Pup can sit, down, wait, stay, leave it, drop it, gentle, settle (go to place/mat), touch in high distraction situations.",
          "Solid understanding of ‘come’ in most mildly distracting scenarios.",
          "Ability to loose leash walk, even in high distraction situations.",
          "Bonus : One additional trick.",
          "Pup understands ‘game show buzzer’ (noise that means no) and clicker training.",
          "Pup is 100% potty trained, understands that he is supposed to potty outside, and will ring bell to indicate – keep in mind puppies aren’t reliably housetrained until 6 months old so keep supervision tight.",
          "Pup is completely socialized in several safe environments.",
          "Pup will be comfortable with body handling similar to what he may experience by a veterinarian or a groomer.",
          "Pup is 100% crate trained.",
          "Pup learns to pay attention and focus, what is appropriate to chow one (and what isn't), and learns self-control.",
          "Pup offers calm polite behavior to get what he wants",
        ],
        image: "program_bt_perfect_puppy",
      },
      {
        programName: "Off Leash Add-on",
        definition: `If you would enjoy reliably working with (and trusting) your dog off leash, here is your chance! With
          this add-on your dog will learn how to behave on and off the leash.`,
        included: [
          "2 additional weeks of training",
          "4 additional in-home training sessions",
          "Remote Training Collar",
        ],
        cost: "$2000",
        expectations: [
          "Your dog to obey all the previously mentioned commands, in any situation, on or off the leash!",
        ],
        image: "program_bt_puppy_off_leash",
      },
    ],
    dayTrainingPrograms: [
      {
        programName: "Simply Manners Day Training Program",
        definition:
          "This program covers your bases to have a calm, polite four legged family member. If you’re looking for your pooch to be more attentive, to listen better and to understand basic commands this is the package for you! You will be taught the initial stages of most basic obedience commands, the key elements of how dogs learn and how to provide your pooch with routine and structure.",
        included: [
          "3 Private In Home Training Sessions at a time convenient for you",
          "4 Day Training Sessions - Let Justine do some of the repetition for you!",
          "Learn how to earn the respect of your dog using Force-Free methods",
          "Training Equipment for each session",
          "Sit, Down, Stay, Wait, Gentle, Leave It, Drop It, Touch",
          "Briefly cover common problem behaviors; housebreaking, jumping up, barking, etc.",
          "Handouts, related articles and weekly homework",
          "Graduation Certificate: Basic Obedience",
          "Access to the Trainer via Phone and E-mail",
        ],
        cost: "$790",
        expectations: [
          "Basic understanding of why dogs do what they dog and how they learn",
          "Basic understanding of how to solve/prevent common problem behaviors",
          "Dog will have basic knowledge of obedience skills, and will be able to perform these skills fluently indoors",
        ],
        image: "program_dt_simply_manners",
      },
      {
        programName: "Simply Manners Plus Leash Walking Day Training Program",
        definition:
          "Does your dog need to learn manners both inside and on a leash? If so, this may be the program for you. You will be taught the fundamental skills to train your dog the early stages of basic manners and loose leash walking. To help you achieve your goal faster Justine will spend time practicing both basic obedience and leash manners during day training sessions.",
        included: [
          "5 One Hour Private In Home Lessons",
          "5 Day Training Lesson",
          "Learn how to earn the respect of your dog using Force-Free methods",
          "Sit, Down, Stay, Wait, Gentle, Leave It, Drop It, Touch, Come, Beginning Recall (come when called) + Loose Leash Walking",
          "Briefly cover common problem behaviors; housebreaking, jumping up, barking, etc.",
          "Handouts, related articles and weekly homework",
          "Photo of you and your pooch",
          "Graduation Certificate: Intermediate Obedience",
          "Access to the Trainer via Phone and E-mail",
        ],
        cost: "$1150",
        expectations: [
          "Understand why dogs do what they dog and how they learn",
          "Understand how to solve/prevent common problem behaviors",
          "Reduction in problem behaviors",
          "Dog will have basic knowledge of obedience skills",
          "Early stages of recall (coming when called)",
          "These skills will be reliable indoors and on leash due to extra practice with the trainer",
          "Human will understand how to begin teaching a loose leash walk",
          "Dog will have the beginnings of a reliable loose leash walk that will be heavily reinforced by one on one day training sessions with the trainer",
        ],
        image: "program_dt_simply_manners_plus_leash",
      },
      {
        programName: "Ultimate Family Dog Day Training Program",
        definition:
          "This is the package for the dog owners who want to be involved in their dog’s education and take that education to the next level. Includes Simply Manners plus Leash Walking. Now that your pooch knows what each command means how do we ensure that he performs every time you ask, no matter what? Justine will be happy to teach you! If you need your pooch to respond well in environments beyond the house or more difficult scenarios let me do those repetitions for you too! This is where you dog and I might pick the kids up at the bus stop, go on a field trip to a park or anywhere that may be beneficial to your dog’s learning process for life with you.",
        included: [
          "8 One Hour Private In Home Lessons",
          "10 Day Training Sessions",
          "FREE Follow Up Session upon completion for Real World Application",
          "Training Equipment (including Head Halter or Front Clip Harness and Long line)",
          "Learn how to earn the respect of your dog using Force-Free methods",
          "Sit, Down, Stay, Wait, Gentle, Leave It, Drop It, Touch, Come, Loose Leash Walking, Recall (come when called) and a few tricks!",
          "Learn how to generalize these behaviors so your dog performs in all situations.",
          "Handouts, related articles and weekly homework",
          "CGC, or Canine Good Citizen, testing can be added on for $25",
          "Photo of you and your pooch",
          "5% Discount on future Services including boarding",
          "Graduation Certificate: Advanced Obedience",
          "Access to the Trainer via Phone and E-mail",
        ],
        cost: "$1975",
        expectations: [
          "Understand why dogs do what they dog and how they learn",
          "Understand how to solve/prevent common problem behaviors",
          "Reduction, possible elimination, in problem behaviors",
          "Dog and human will understand how to work as a team and use their obedience skills in most scenarios",
          "Your dog will recieve one on one practice using his obedience skills in public with the trainer during day training sessions",
          "Human will understand how to teach a loose leash walk",
          "Dog will loose leash walk",
          "Your dog will have a lot of practive loose leash walking in parks, Broad Ripple, or other distracting areas with one on one guidance from the trainer.",
        ],
        image: "program_dt_ultimate_family_dog",
      },
      {
        programName: "Feisty Fido - Behavioral Modification Program",
        definition:
          "This is the program for dogs who have issues beyond what basic manners can repair (shy, overexcited and reactive dogs) and the owners who want to actively help their dog to recovery. We will begin by learning manners to help your pooch learn to focus and work for what he wants. After you and your dog have learned basic commands and how to work as a team Justine will tailor make a plan to address your specific needs. Frequently this includes teaching you Behavior Adjustment Training and/or Counter Conditioning/Desensitization. This program may or may not include leash walking depending on your dog’s specific needs.",
        included: [
          "6 One Hour Private In Home Lessons",
          "15 Day Training Sessions",
          "2 FREE Follow Up Sessions upon completion for Real World Application",
          "Training Equipment (including Head Halter or Front Clip Harness and a Long line)",
          "Learn how to earn the respect of your dog using Force-Free methods",
          "Basic Obedience commands - depend on your needs - Focus wil be on teaching your pooch calm, polite behavior.",
          "Handouts, related articles and weekly homework",
          "Photo of you and your pooch",
          "10% Discount on future Services including boarding",
          "Graduation Certificate: Life Skills",
          "Access to the Trainer via Phone and E-mail",
        ],
        cost: "$2275",
        expectations: [
          "This will depend on why you need this program. You can expect that your dog’s issues with (fear towards dogs/people/travel, reactivity towards people/dogs, guarding of toys/food/people, hyperactivity/not being able to settle, etc.) will be at a manageable place if not resolved by the end of this program.",
          "NOTE: Depending on your particular situation your dog may require more or fewer sessions.",
        ],
        image: "program_dt_fiesty_fido",
      },
    ],
  }))
);

// const toastFor = (action, successMessage, successCode = 0) => ({
//   message: action.payload?.message || successMessage,
//   statusCode: action.payload?.statusCode || successCode
// });

// Create protected page hook
// export const useProtected = (toastMessage = 'You must be signed in to view this page') => {
//   const { dispatch, user } = useStore();
//   const history = useHistory();

//   useEffect(() => {
//     if (!user.token) {
//       dispatch({ type: actions.TOAST, payload: { message: toastMessage , statusCode: 1 } });
//       history.replace('/login');
//     }
//   }, [user]);
// };
