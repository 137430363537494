import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Components
import {
  BoardAndTrain,
  Boarding,
  BTAdult,
  BTPuppy,
  BuyATrainedPuppy,
  DayTraining,
  PrivateLessons,
  TrainingAll,
} from "../Components";
// Store
import { pathTitles, useStore } from "../Store/Store";

export const Training = () => {
  const getLink = useStore((state) => state.getLink);
  return (
    <>
      <Switch>
        <Route
          path={getLink(pathTitles.BOARDANDTRAIN)}
          component={BoardAndTrain}
        />
        <Route
          exact
          path={getLink(pathTitles.BOARDANDTRAINADULT)}
          component={BTAdult}
        />
        <Route
          exact
          path={getLink(pathTitles.BOARDANDTRAINPUPPY)}
          component={BTPuppy}
        />
        <Route exact path={getLink(pathTitles.BOARDING)} component={Boarding} />
        <Route
          path={getLink(pathTitles.BUYATRAINEDPUPPY)}
          component={BuyATrainedPuppy}
        />
        <Route path={getLink(pathTitles.DAYTRAINING)} component={DayTraining} />
        <Route
          path={getLink(pathTitles.PRIVATELESSONS)}
          component={PrivateLessons}
        />
        <Route path={getLink(pathTitles.TRAINING)} component={TrainingAll} />
        <Redirect
          exact
          from={getLink(pathTitles.TRAINING)}
          to={getLink(pathTitles.ALLTRAININGPROGRAMS)}
        />
      </Switch>
    </>
  );
};
