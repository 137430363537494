import React, { useEffect } from "react";
import { Toast } from "react-bootstrap";

import { actions, useStore } from "../../Store/Store";

export const ToastComponent = () => {
  const dispatch = useStore((state) => state.dispatch);
  const toast = useStore((state) => state.toast);
  const fadeClass = toast?.message ? "toast-show" : "";

  // When the toast state changes
  useEffect(() => {
    let anim = null;
    if (toast.message) {
      anim = setTimeout(() => dispatch({ type: actions.UNTOAST }), 5000);
    }

    return () => {
      clearTimeout(anim);
    };
  }, [toast]);

  return (
    <>
      <Toast className={`toast toast-${toast.statusCode} ${fadeClass}`}>
        <Toast.Body>{toast.message}</Toast.Body>
      </Toast>
    </>
  );
};
