import React from "react";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, Team } from "..";

export const TeamAurora = () => {
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const aboutDetails = useStore((state) => state.aboutDetails);

  const overlay = {
    where: "aboutAurora",
    title: "Meet Aurora",
    caption:
      "Aurora follows Justine in perfect heel, waiting for the next request.",
  };
  const info = {
    routes: {
      Home: "/",
      "Todo's Team": getLink(pathTitles.MEETTHETEAM),
      "Meet Aurora": getLink(pathTitles.MEETAURORA),
    },
    body: aboutDetails.AURORA.body,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <Team info={info} />
        </>
      )}
    </>
  );
};
