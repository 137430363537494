import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";

// Components
import { Programs } from "../../Components";
// Store
import { actions, useStore } from "../../Store/Store";

export const TrainingInfo = (props) => {
  const info = props.info;

  const dispatch = useStore((state) => state.dispatch);
  const user = useStore((state) => state.user);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    fetch("https://msr-todos-web-api.herokuapp.com/auth/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: user.username,
        password: user.password,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.token) {
          dispatch({ type: actions.LOGIN, payload: response.token });
        }
      });
  }, []);

  const handleFormChange = (e) => {
    let inputName = e.target.name;
    let inputValue = e.target.value;
    setFormData((state) => ({ ...state, [inputName]: inputValue }));
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      fetch("https://msr-todos-web-api.herokuapp.com/contact/send_email/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + user.token,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status_code === 200) {
            dispatch({
              type: actions.TOAST,
              payload: {
                message:
                  "Successfully emailed Justine! She'll reach out to you soon.",
                statusCode: 200,
              },
            });
            resetForm();
          } else {
            dispatch({
              type: actions.TOAST,
              payload: {
                message: "An error occurred. Please try again",
                statusCode: 400,
              },
            });
          }
        });
    }
    setValidated(true);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      message: "",
    });
    setValidated(false);
  };
  return (
    <>
      <Container className="small_container common_container">
        <Row>
          <Card.Header className="common_header">
            {Object.keys(info.routes).map((routeName, i) => {
              return (
                <>
                  <Link
                    key={`${info.routes[routeName]}_${i}`}
                    to={info.routes[routeName]}
                  >
                    {routeName}
                  </Link>
                  {i < Object.keys(info.routes).length - 1 && (
                    <>&nbsp;/&nbsp; </>
                  )}
                </>
              );
            })}
          </Card.Header>
        </Row>
        <Row>
          <Col sm={10} lg={7}>
            <Card className="common_card border_right">
              <Card.Title>{info.body.title}</Card.Title>
              <Card.Body>{info.body.info}</Card.Body>
              <div className={`${info.body.image} common_image`}></div>
              <figcaption className="figure-caption">
                {info.body.imageCaption}
              </figcaption>
              {info.body.moreInfo?.length > 0 &&
                info.body.moreInfo.map((p, i) => {
                  return <Card.Body key={i}>{p}</Card.Body>;
                })}
              {info.programs &&
                info.programs.map((p, i) => {
                  return <Programs key={`${p.programName}_${i}`} program={p} />;
                })}
              {info.programs && info.body.otherServices && (
                <Card.Subtitle>
                  Other Services you might like :&nbsp;
                </Card.Subtitle>
              )}
              {info.body.otherServices && !info.programs && (
                <Card.Subtitle>Continue reading :&nbsp;</Card.Subtitle>
              )}
              {info.body.otherServices &&
                Object.keys(info.body.otherServices).map((serviceName, i) => {
                  return (
                    <Accordion
                      className="training_accordion"
                      key={`${info.body.otherServices[serviceName]}_${i}`}
                    >
                      <Accordion.Item
                        eventKey={`${info.body.otherServices[serviceName]}_${i}`}
                      >
                        <Accordion.Header>
                          {info.body.otherServices[serviceName].title}
                        </Accordion.Header>
                        <Accordion.Body className="training_accordion_body">
                          {info.body.otherServices[serviceName].info}
                          <Button
                            className="training_accordion_button"
                            as={Link}
                            to={info.body.otherServices[serviceName].link}
                          >
                            Read More
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
            </Card>
          </Col>
          <Col sm={10} lg={4} className="training_form_col">
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e)}
              className="training_contact_us_form"
            >
              <p className="h2 text-center m-4">Contact Us today!</p>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide your name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email address to reach you at.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Phone"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a phone number Justine can reach you at.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  rows="5"
                  required
                  type="message"
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleFormChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please let us know how we can help.
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="training_contact_section_button" type="submit">
                Submit&nbsp;&nbsp;&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                </svg>
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

TrainingInfo.propTypes = {
  info: PropTypes.object,
};
