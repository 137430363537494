import React from "react";

// import { Card, Col, Container, Row } from "react-bootstrap";
// import ReactPlayer from "react-player";
// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { Loader, PageOverLay, Team } from "..";

export const TeamKyra = () => {
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const aboutDetails = useStore((state) => state.aboutDetails);
  // const w = useStore((state) => state.width);

  const overlay = {
    where: "aboutKyra",
    title: "Meet Kyra",
    caption: "Kyra snuggles a sold Buy A Trained Puppy.",
  };
  const info = {
    routes: {
      Home: "/",
      "Todo's Team": getLink(pathTitles.MEETTHETEAM),
      "Meet Kyra": getLink(pathTitles.MEETKYRA),
    },
    body: aboutDetails.KYRA.body,
  };
  // let width = "100%";
  // if (w > 600) {
  //   width = "360px";
  // }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <Team info={info} />
          {/* <Container className="small_container common_container">
            <Row>
              <Col>
                <Card className="common_card">
                  <Card.Title>Kyra working with Chico</Card.Title>
                  <div
                    className="player-wrapper"
                    style={{ margin: "0 auto 10rem auto" }}
                  >
                    <ReactPlayer
                      className="react-player"
                      url="https://www.facebook.com/IndyInHomeDogTrainer/videos/372658911357973/"
                      width={width}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </Container> */}
        </>
      )}
    </>
  );
};
