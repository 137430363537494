import PropTypes from "prop-types";
import React from "react";
import { Card, Container } from "react-bootstrap";

export const PageOverLay = (props) => {
  const { where, title, caption } = props.overlay;

  return (
    <>
      <div className="page_overlay">
        <div className="page_overlay_slides">
          <div className="page_overlay_slide">
            <span className="page_overlay_slide_bg_wrap">
              <span className={`${where} page_overlay_slide_bg`}></span>
            </span>
            <Container className="page_overlay_container clear">
              <Card className="page_overlay_container_inner">
                <Card.Body>
                  <Card.Title>
                    <h1>{title}</h1>
                  </Card.Title>
                  <figcaption className="figure-caption">{caption}</figcaption>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

PageOverLay.propTypes = {
  overlay: PropTypes.object,
};
