import React, { useEffect } from "react";

// Components
import { Loader, PageOverLay, TrainingInfo } from "../../Components";
// Store
import { pathTitles, useStore } from "../../Store/Store";

export const BuyATrainedPuppy = () => {
  const checkServices = useStore((state) => state.checkServices);
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const trainingDetails = useStore((state) => state.trainingDetails);

  useEffect(() => {
    checkServices(trainingDetails);
  }, []);
  const overlay = {
    where: "buyATrainedPuppy",
    title: "Buy A Trained Puppy",
    caption: "This puppy has a first name, it's OSCAR.",
  };
  const info = {
    routes: {
      Home: "/",
      Training: getLink(pathTitles.TRAINING),
      "Buy a Trained Puppy": getLink(pathTitles.BUYATRAINEDPUPPY),
    },
    body: trainingDetails.BUYATRAINEDPUPPY.body,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <TrainingInfo info={info} />
        </>
      )}
    </>
  );
};
