import React, { useEffect } from "react";

// Components
import { Loader, PageOverLay, TrainingInfo } from "../../Components";
// Store
import { pathTitles, useStore } from "../../Store/Store";

export const PrivateLessons = () => {
  const checkServices = useStore((state) => state.checkServices);
  const getLink = useStore((state) => state.getLink);
  const loading = useStore((state) => state.loading);
  const trainingDetails = useStore((state) => state.trainingDetails);
  const inHomePrograms = useStore((state) => state.inHomePrograms);

  useEffect(() => {
    checkServices(trainingDetails);
  }, []);
  const overlay = {
    where: "privateLessons",
    title: "In Home Training",
    caption:
      "Roger, incredibly reactive to humans, but lucky enough to have a dedicated momma.",
  };
  const info = {
    routes: {
      Home: "/",
      Training: getLink(pathTitles.TRAINING),
      "Private Lessons": getLink(pathTitles.PRIVATELESSONS),
    },
    body: trainingDetails.PRIVATELESSONS.body,
    programs: inHomePrograms,
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageOverLay overlay={overlay} />
          <TrainingInfo info={info} />
        </>
      )}
    </>
  );
};
